import {useRef, useState} from "react";
import Axios from "../axios/Axios";
import {useDispatch} from "react-redux";
import {activeUserToken} from "../store/reducers/userGuardReducer";
import {regEmail, regPassword} from "../util/reg";
import {useNavigate} from "react-router-dom";

const Login = () => {
    const [showPassword , setShowPassword] = useState(false);
    const [idError , setIdError] = useState(false);
    const [passwordError , setPasswordError] = useState(false);
    const [loginError , setLoginError] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();


    const [password , setPassword] = useState("");
    const [id , setId] = useState("");

    const idRef = useRef();
    const passwordRef = useRef();

    const handleId = (event) => {
        if(regEmail.test(event.target.value)) {
            setIdError(false);
        }
    }

    const handleBlurId = (event) => {
        if(!regEmail.test(event.target.value)) {
            setIdError(true);
            setLoginError(false);
        } else {
            setId(event.target.value)
        }
    }

    const handlePassword = (event) => {
        if(regPassword.test(event.target.value)) {
            setPasswordError(false);
        }
    }

    const handleBlurPassword = (event) => {
        if(!regPassword.test(event.target.value)) {
            setLoginError(false);
            setPasswordError(true);
        } else {
            setPassword(event.target.value);
        }
    }

    const handleSubmit = () => {
        const data = new FormData();
        id === "" ? data.append("id" , idRef.current.value) : data.append("id", id);
        password === "" ?  data.append("password" , passwordRef.current.value) :  data.append("password", password);

        Axios.post("/user/auth/login" , data , true).then( result => {
            userToken(result)
        })
    }


    const enterFunction = (event) => {
        if(event.keyCode === 13){
            handleSubmit()
        }
    }

    const userToken = (userInfo) => {
        dispatch(
            activeUserToken({
                isToken: true,
                token: userInfo.token,
                name: userInfo.user.name,
                email: userInfo.user.email
            })
        )
        navigate("/user/myPage")
    }



    const handleShowPassword = (e) => {
        setShowPassword(!showPassword)
    }
    return (
        <section id="wrap" className="wrap subpage-wrap">
            <h2 className="blind">본문</h2>
            <div className="container">
                <div className="content">
                    <article className="login-wrap">
                        <h3 className="content-title">로그인</h3>
                        <div className="content-box">
                            <div className="form-area">
                                <form className="form-vertical" >
                                    {/*// <!-- [dev] 폼 에러인 경우, form-group 에 has-error 클래스 추가해주세요 -->*/}
                                    <div className={idError ? "form-group has-error" : "form-group"}>
                                        <label className="form-label" htmlFor="input-userMail">이메일</label>
                                        <input className="form-input" id="input-userMail" type="email"
                                               name="id"
                                               placeholder="이메일을 입력해주세요." onChange={(e)=> {
                                                    handleId(e);
                                                }} onBlur={ (e) => {
                                                    handleBlurId(e);
                                                }}
                                                ref={idRef}
                                        />
                                        {idError ? <p className="form-error-check" style={{marginTop:10}}>이메일 형식으로 입력해주세요.</p> : null}
                                    </div>
                                    <div className= {passwordError ? "form-group has-error has-icon-right" : "form-group has-icon-right"} >
                                        <label className="form-label" htmlFor="input-userPW">비밀번호</label>
                                        <input className="form-input" id="input-userPW" type={ showPassword ? "text" : "password"}
                                               name="password"
                                               placeholder="비밀번호를 입력 해주세요."
                                               onChange={(e) => {
                                                   handlePassword(e);

                                               }}
                                               onKeyDown={enterFunction}
                                               onBlur={ (e)=> {
                                                   handleBlurPassword(e);
                                               }}
                                                ref={passwordRef}
                                        />
                                        <i className="icon form-icon btn-icon eye-show" attr-icon="eye-hide"
                                           role="button" onClick={ (e) => {
                                               handleShowPassword(e);
                                           }} ></i>
                                    </div>
                                    {loginError ? <p className="form-error-check">아이디 혹은 비밀번호를 확인해주세요</p> : null }
                                    <div className="btn-submit-group">
                                        <button type="button" className="btn-form-submit btn-block" onClick={ () => {
                                            handleSubmit()
                                        }}>로그인</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div style={{marginTop: 3}}>
                            <button type="button" className="btn" style={{float:"right", padding:5}} onClick={ () => {
                                navigate("/user/findPassword");
                            }}><span style={{fontSize:14, color: "gray" , textDecoration:"underline"}}>비밀번호 찾기</span></button>
                            <button type="button" className="btn" style={{float:"right" , padding:5}} onClick={ () => {
                                navigate("/user/findEmail");
                            }}><span style={{fontSize:14, color: "gray", textDecoration:"underline"}}>아이디 찾기</span></button>
                        </div>
                    </article>
                </div>
            </div>
        </section>
    );
}

export default Login;