
const St06 = () => {
    return (
        <section id="st06" className="section section-zoomInstall">
            <div className="section-inner">
                <div className="section-title">
                    <h3 className="st-tit st06-tit">
                        <img src="/images/pc/img_st06_tit@2x.png" alt="" />
                            <span className="blind">줌 설치법</span>
                    </h3>
                </div>
                <div className="section-content">
                    <picture className="st06-cont01">
                        <source media="(max-width: 1024px)" srcSet="/images/mo/img_st06_cont01@2x.png" />
                            <source media="(min-width: 1025px)" srcSet="/images/pc/img_st06_cont01@2x.png" />
                                <img src="/images/pc/img_st06_cont01@2x.png" alt="사용기기: 태블릿 PC, 데스크탑, 노트북" />
                    </picture>

                    <div className="btn-box">
                        <a href="https://zoom.us/download" target="_blank" className="btn btn-download">
                            <img src="/images/pc/btn_download@2x.png" alt="줌 다운로드" />
                        </a>
                    </div>
                    <div className="txt-info">
                        <p>* 학습자는 단순 참가자이므로 줌에 회원가입은 필수가 아닙니다. (회원가입 없이 수업 참여 가능)</p>
                        <p>
                            * 줌 다운로드 방법 자세한 설명
                            <a href="https://fervors.tistory.com/168"
                               target="_blank">https://fervors.tistory.com/168</a>
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default St06;