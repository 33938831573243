import Sections from "./sections";

const Main = () => {

    return (
        <>
            {/*<div className="site-nav-dimm"></div>*/}
            <Sections/>
        </>
    )
}

export default Main;