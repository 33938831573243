import {store} from "../store";
import {createContext, useEffect, useReducer} from "react";
import userGuardReducer, {activeUserToken} from "../store/reducers/userGuardReducer";
import PropTypes from "prop-types";
import { useSelector} from "react-redux";

const isToken = store.getState().userGuardReducer.isToken;

const initialState = {
    isToken: isToken != null ? isToken : false,
    name: "",
    status: "",
    token: ""
};

const UserContext = new createContext(null);

export const UserProvider = ({children}) => {

    const selector = useSelector( (state) => state.userGuardReducer);

    const [state , dispatch] = useReducer(userGuardReducer , {
                                                                            isToken: isToken != null ? isToken : false,
                                                                            token: "",
                                                                            name: "",
                                                                            email: ""
                                                                        }
                              )

    return (
        <UserContext.Provider
            value={{
                ...state,
                dispatch
            }}
        >
            {children}
        </UserContext.Provider>
    );
}

UserProvider.propTypes = {
    children: PropTypes.node
};

export default UserContext;