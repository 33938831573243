import {useRef, useState} from "react";
import Axios from "../axios/Axios";
import {useDispatch} from "react-redux";
import {activeUserToken} from "../store/reducers/userGuardReducer";
import {regEmail, regPassword, regPhoneNumber} from "../util/reg";
import {useNavigate} from "react-router-dom";
import {showAlert} from "../store/reducers/alertDialogSlideRedux";

const FindPassword = () => {

    const [idError , setIdError] = useState(false);
    const [phoneNumberError , setPhoneNumberError] = useState(false);
    const [loginError , setLoginError] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [email ,setEmail] = useState("");
    const [phoneNumber , setPhoneNumber] = useState("");

    const handleId = (event) => {
        if(regEmail.test(event.target.value)) {
            setIdError(false);
        }
    }

    const handleBlurId = (event) => {
        if(!regEmail.test(event.target.value)) {
            setIdError(true);
            setLoginError(false);
        } else {
            setEmail(event.target.value)
        }
    }

    const handlePhoneNumber = (event) => {
        if(regPhoneNumber.test(event.target.value)) {
            setPhoneNumberError(false);
        }
    }

    const handleBlurPhoneNumber = (event) => {
        if(!regPhoneNumber.test(event.target.value)) {
            setLoginError(false);
            setPhoneNumberError(true);
        } else {
            setPhoneNumber(event.target.value);
        }
    }

    const handleSubmit = () => {
        const data = new FormData();
        data.append("sendEmail" , email);
        data.append("phone_number" , phoneNumber)

        Axios.post("/user/mail/sendEmail" , data , true).then( result => {

            const message = '임시 비밀번호를 이메일로 전송해드렸습니다. 로그인 후 비밀번호를 변경해주세요.';
            if(result.searchResult === 1) {
                dispatch(
                    showAlert({
                        items: 1,
                        title: '비밀번호 찾기 성공',
                        message: message,
                        text1: '닫기'
                    }),
                );
            } else {
                dispatch(
                    showAlert({
                        items: 1,
                        title: '비밀번호 찾기 실패',
                        message: '올바른 정보를 입력해 주세요.',
                        text1: '닫기'
                    }),
                );
            }
        })
    }


    const enterFunction = (event) => {
        if(event.keyCode === 13){
            handleSubmit()
        }
    }

    return (
        <section id="wrap" className="wrap subpage-wrap">
            <h2 className="blind">본문</h2>
            <div className="container">
                <div className="content">
                    <article className="login-wrap">
                        <h3 className="content-title">비밀번호 찾기</h3>
                        <div className="content-box">
                            <div className="form-area">
                                <form className="form-vertical" >
                                    {/*// <!-- [dev] 폼 에러인 경우, form-group 에 has-error 클래스 추가해주세요 -->*/}
                                    <div className={idError ? "form-group has-error" : "form-group"}>
                                        <label className="form-label" htmlFor="input-userMail">이메일</label>
                                        <input className="form-input" id="input-userMail" type="email"
                                               name="id"
                                               placeholder="이메일을 입력해주세요."
                                       onChange={(e)=> {
                                            handleId(e);
                                        }} onBlur={ (e) => {
                                            handleBlurId(e);
                                        }}
                                        />
                                        {idError ? <p className="form-error-check" style={{marginTop:10}}>이메일 형식으로 입력해주세요.</p> : null}
                                    </div>
                                    <div className= {phoneNumberError ? "form-group has-error has-icon-right" : "form-group has-icon-right"} >
                                        <label className="form-label" htmlFor="input-userPW">휴대폰 번호</label>
                                        <input className="form-input" id="input-userPW" type="tel"
                                               name="password"
                                               placeholder="휴대폰 번호를 입력 해주세요."
                                               onChange={(e) => {
                                                   handlePhoneNumber(e);
                                               }}
                                               onKeyDown={enterFunction}
                                               onBlur={ (e)=> {
                                                   handleBlurPhoneNumber(e);
                                               }}
                                        />
                                    </div>
                                    {loginError ? <p className="form-error-check">아이디 혹은 휴대폰번호 형식을 확인해주세요</p> : null }
                                    <div className="form-input-desc">
                                        <p>이메일로 비밀번호를 전송해 드립니다.</p>
                                        <p className="form-error-check">비밀번호 찾기 후 마이페이지에서 비밀번호를 변경해주세요.</p>
                                    </div>

                                    <div className="btn-submit-group">
                                        <button type="button" className="btn-form-submit btn-block" onClick={ () => {
                                            handleSubmit()
                                        }}>비밀번호 찾기</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div style={{marginTop: 3}}>
                            <button type="button" className="btn" style={{float:"right", padding:5}} onClick={ () => {
                                navigate("/login");
                            }}><span style={{fontSize:14, color: "gray" , textDecoration:"underline"}}>로그인</span></button>

                            <button type="button" className="btn" style={{float:"right" , padding:5}} onClick={ () => {
                                navigate("/user/findEmail");
                            }}><span style={{fontSize:14, color: "gray", textDecoration:"underline"}}>아이디 찾기</span></button>
                        </div>
                    </article>
                </div>
            </div>
        </section>
    );
}

export default FindPassword;