
const St011 = () => {
    return (
        // 파닉스영어회화 강점
        <section id="st011" className="section section-strength">
            <div className="section-inner">
                <h3 className="blind">파닉스영어회화 강점</h3>
                <div className="section-content">
                    <div className="strength-container">
                        <div className="card full">
                            <div className="card-title">
                                파닉스와 기초영어회화를 빠르게 완성합니다.
                            </div>
                            <div className="card-content">
                                <div className="seperate">
                                    - 수업 시간(35분)이 길고, <br/>
                                    - 수업 회수(주3회)도 많고 <br/>
                                    - 아이들이 숙제를 항상 하는 편이어서 <br/>
                                    - 6개월 만에
                                </div>
                                <div className="seperate">
                                    - 파닉스 전과정(알파벳 첫소리, 단모음, 장모음, 이중모음, 이중자음)과 <br/>
                                    - 기초 영어회화를 완성합니다. <br/>
                                    - 6개월 이후 아이들이 다양한 리딩 포함 영어를 쉽게 대하는 것을 기대하세요.
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-title">
                                또래 친구들끼리 즐겁게 적극적으로 수업에 참여합니다.
                            </div>
                            <div className="card-content">
                                - 4스텝 파닉스 화상 수업은 PPT와 영상을 사용해서 수업이 진행되며, <br/>
                                - 아이들은 한국인 선생님과 원어민 동영상 선생님의 질문에 <em>50번 이상</em> 대답을 합니다. <br/>
                                - 아이들 서로간의 영향으로 <em>큰 소리로 대답</em>을 하며 <br/>
                                - 즐겁게 적극적으로 수업에 참여합니다.
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-title">
                                학원 대비 수업 효과는 높고, 수업료는 많이 낮습니다.
                            </div>
                            <div className="card-content">
                                - 학원과 같이 숙제를 관리 합니다. <br/>
                                - 수업 내용을 충분히 익히는 즉, 수업과 연관된 숙제(영상, 앱, 교재)를 제공하기 때문에 <br/>
                                - 학원 대비 파닉스 학습 효과가 더 높습니다.
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-title">
                                숙제를 마루지 않습니다.
                            </div>
                            <div className="card-content">
                                - 한국인 선생님이 매 수업 숙제 검사를 하고 <br/>
                                - 함께 수업하는 아이들이 숙제를 미루지 않으면서 <br/>
                                - 모든 학습자가 숙제를 잘 하는 편입니다.
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-title">
                                수업 시간에 빠지는 경우가 거의 없습니다.
                            </div>
                            <div className="card-content">
                                - 다른 아이들과 수업하면서 <br/>
                                - 유치원, 학원, 초등학교 등에서 수업하는 것과 같은 의무감을 가지면서<br/>
                                - 거의 모든 수업을 참여하고 있습니다.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default St011;