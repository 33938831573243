import {createSlice} from "@reduxjs/toolkit";


//const isToken = store.getState().userGuardReducer.isToken;

const initialState = {
    //isToken: isToken != null ? isToken : false,
    isToken: false,
    name: "",
    email: "",
    token: ""
};

const userGuardReducer = createSlice({
    name: 'userGuardReducer',  // state 이름
    initialState,// state 값
    reducers: { // 수정하는 함수 // state -> 기존 스테이트 ,
        activeUserToken(state, action) {
            state.isToken = action.payload.isToken;
            state.token = action.payload.token;
            state.name = action.payload.name;
            state.email= action.payload.email;
        },
        deleteUserToken(state, action) {
            state.isToken = false;
            state.token = "";
            state.name = "";
            state.email= "";
        }
    }
});


export default userGuardReducer.reducer;

export const { activeUserToken  , deleteUserToken } = userGuardReducer.actions;