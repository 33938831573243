import { useNavigate} from "react-router-dom";
import {useEffect, useRef} from "react";
import useMenuModal from "../../Hooks/useMenuModal";
import {hideMenuModal, showMenuModal} from "../../store/reducers/menuModalReducer";
import {useDispatch} from "react-redux";
import {Link} from "react-scroll";
import useUser from "../../Hooks/useUser";
import {activeUserToken, deleteUserToken} from "../../store/reducers/userGuardReducer";

const Nav = () => {

    const navigate = useNavigate();
    const {isShow} = useMenuModal();
    const dispatch = useDispatch();
    const {isToken} = useUser();

    const html = document.getElementsByTagName("html")[0];

    return(
        <nav className={isShow ? "site-nav is-open" : "site-nav"}>
            <h2 className="blind">모바일 전체메뉴</h2>
            <div className="nav">
                <p className="site-nav-logo">
                    <span className="blind">루시맥스</span>
                    <a href="/">
                        <img src="/images/logo@2x.png" alt="루시맥스"/>
                    </a>
                </p>
                <ul className="nav-list">
                    <li className="nav-item">
                        <a href="/#st02"  onClick={()=> {
                            dispatch(
                                hideMenuModal()
                            )
                        }} className="nav-link">수업 모습</a>
                    </li>
                    <li className="nav-item">
                        <a href="/#st04"  onClick={()=> {
                            dispatch(
                            hideMenuModal()
                            )
                        }} className="nav-link">수업 순서</a>
                    </li>
                    <li className="nav-item">
                        <a href="/#st06"  onClick={()=> {
                            dispatch(
                            hideMenuModal()
                            )
                        }} className="nav-link">줌설치 방법</a>
                    </li>
                    <li className="nav-item">
                        <a href="/#st07"  onClick={()=> {
                            dispatch(
                            hideMenuModal()
                            )
                        }} className="nav-link">줌수업 참가</a>
                    </li>
                </ul>
                <div className="site-nav-foot">
                    <div className="btn-group-box">
                        <div className="group">
                            <button onClick={()=> {
                                dispatch(
                                    hideMenuModal()
                                )
                                html.classList.remove('open-modal');
                                navigate('/PurchaseCourse/1', {replace: true})
                            }} className="btn btn-block btn-buy custom-hide-button">
                                <span className="txt">수강권 구매</span>
                            </button>
                        </div>


                        {!isToken ?
                            <div className="group group-line">
                                <button onClick={() => {
                                    dispatch(
                                        hideMenuModal()
                                    )
                                    html.classList.remove('open-modal');
                                    navigate('/login', {replace: true});
                                }} className="btn btn-text btn-login">
                                    <span className="txt">LOGIN</span>
                                </button>
                                <span className="bar"></span>
                                <button onClick={() => {
                                    dispatch(
                                        hideMenuModal()
                                    )

                                    html.classList.remove('open-modal')
                                    navigate('/registry', {replace: true})
                                }} className="btn btn-text btn-join custom-hide-button">
                                    <span className="txt">회원가입</span>
                                </button>
                            </div>
                            :
                            <div className="group group-line">
                                <button type="button" className="btn btn-text btn-logout" onClick={()=> {
                                    dispatch(
                                        deleteUserToken()
                                    )
                                    navigate("/" , {replace:true})
                                }
                                }>
                                    <span className="txt">LOGOUT</span>
                                </button>
                                <span className="bar"></span>
                                <button onClick={()=> {
                                    dispatch(
                                        hideMenuModal()
                                    )
                                    navigate("/user/mypage" , {replace:true})
                                    html.classList.remove('open-modal');
                                }} className="btn btn-text btn-mypage custom-hide-button">
                                    <span className="txt">마이페이지</span>
                                </button>
                            </div>
                        }
                    </div>
                    <p className="copyright">ⓒ lucymaxkorea</p>
                </div>
            </div>
        </nav>
    );
}

export default Nav;