import {useEffect} from "react";
import {useNavigate} from "react-router-dom"
const MissingPage = () => {

    const navigate = useNavigate();

    useEffect(()=> {
            navigate("/")
    }, [navigate])

    return null;
}

export default MissingPage