import {useLocation, useNavigate} from "react-router-dom";

const SuccessFindEmail = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const email = location.state;
    return (
     <section id="wrap" className="wrap subpage-wrap">
         <h2 className="blind">본문</h2>
         <div className="container">
             <div className="content is-graybody">
                 <article className="purchase-wrap">
                     <h3 className="content-title">아이디 찾기</h3>
                     <div className="content-box">
                         <div className="form-area">
                             <form className="form-vertical" method="POST" action="">
                                 <div className="form-group">
                                     <div className="form-card form-total-price">
                                         <p className="title" style={{margin: "auto"}}>
                                             입력한 정보로 조회된 아이디는 <span className="price" style={{margin: "0 10px"}}>{email}</span> 입니다.
                                         </p>
                                     </div>
                                 </div>
                                 <div className="btn-submit-group is-flex">
                                     <button type="button" className="btn btn-form-submit" onClick={()=>{navigate("/login");}} style={{margin: "auto"}}>로그인</button>
                                 </div>
                             </form>
                         </div>
                     </div>
                 </article>
             </div>
         </div>
     </section>
    );
 }

 export default SuccessFindEmail;