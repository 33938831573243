import {useSelector} from "react-redux";


const useUser = () => {
    const user = useSelector(state => state.userGuardReducer);
    //const context = useContext(UserContext);

    if(!user) throw new Error("프로바이더가 없습니다.");

    return user;
}

export default useUser;