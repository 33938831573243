import Header from "./Component/Header/Header";
import Nav from "./Component/Header/Nav";
import Footer from "./Component/Footer";
import Routes from "./routes";
import {UserProvider} from "./Guard/UserContext";
import {useDispatch} from "react-redux";
import {initData} from "./axios/Axios";
import {useNavigate} from "react-router-dom";
import Loadable from "./Component/Loadable";
import {lazy} from "yup";
import Loader from "./Component/Loader";
import {Suspense, useEffect} from 'react';

const RoutesLazy = Loadable(lazy(() => import("./routes")));


const App = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        initData(dispatch , navigate);
    }, [])


      return (
        <div className="App">
            <div id="accessibility">
                <a href="#wrap">본문 바로가기</a>
            </div>
            <Header/>
            <Nav/>
            <div className="site-nav-dimm" ></div>
            <UserProvider>
                <Suspense fallback={<Loader />}>
                     <Routes />
                </Suspense>
            </UserProvider>
            {/*<Sections/>*/}
            <Footer/>
        </div>
      );
}

export default App;
