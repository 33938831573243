
const St04 = () => {
    return (
        <section id="st04" className="section section-eduFlow">
            <div className="section-inner">
                <div className="section-title">
                    <h3 className="st-tit st04-tit">
                        <img src="/images/pc/img_st04_tit@2x.png" alt=""/>
                        <span className="blind">수업 순서 요약</span>
                    </h3>
                </div>
                <div className="section-content">
                    <picture className="st04-cont01">
                        <source media="(max-width: 1024px)" srcSet="images/mo/img_st04_cont01@2x.png"/>
                        <source media="(min-width: 1025px)" srcSet="images/pc/img_st04_cont01@2x.png"/>
                        <img src="/images/pc/img_st04_cont01@2x.png" alt="1. 수업참여(최대 5명) 2. 숙제 검사 3. 수업 시작" />
                    </picture>
                </div>
            </div>
        </section>
    );
}

export default St04;