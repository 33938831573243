import {createAction, handleActions} from 'redux-actions';

const SHOW = 'alertDialogSlideRedux/SHOW';
const HIDE = 'alertDialogSlideRedux/HIDE';

export const showAlert = createAction(SHOW, (options) => options);
export const hideAlert = createAction(HIDE);

const initialState = {
    isShow: false,
    items: 1,
    title: '',
    message: '',
    text1: '',
    sucCallback: null,
    text2: '',
    failCallback: null
};

export default handleActions(
    {
        [SHOW]: (state, action) => {
            return {
                ...initialState,
                ...action.payload,
                isShow: true,
            }
        },
        [HIDE]: (state) => ({
            ...initialState,
            ...state,
            isShow: false,
        }),
    },
    initialState,

);
