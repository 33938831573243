import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import useUser from "../Hooks/useUser";
import {useEffect} from "react";
import {activeUserToken, deleteUserToken} from "../store/reducers/userGuardReducer";
import {useNavigate} from "react-router-dom";

const UserGuard = ({ children }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {isToken , name , email , token} = useUser();
        // useSelector((state) => state.userGuardReducer);

    useEffect(() => {
        if(isToken === false || name === "" || email === "") {
            dispatch(
               deleteUserToken()
            )
            navigate("/login" , {replace:true})
        }

    } ,[])
    return (
      <>
          {children}
      </>
    );
}

UserGuard.propTypes = {
    children: PropTypes.node
};

export default UserGuard;