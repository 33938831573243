import {useEffect} from "react";
import axios from "axios";
import base64 from "base-64";
import {useNavigate} from "react-router-dom";
import Axios from "../../axios/Axios";

const Success = () => {
    const navigate = useNavigate();
    // (process.env.REACT_APP_BASE_URL + `/success?paymentKey=${paymentKey}&amount=${amount}&orderId=${orderId}`)
    let orderId = new URL(window.location.href).searchParams.get("orderId");
    let paymentKey = new URL(window.location.href).searchParams.get("paymentKey");
    let amount = new URL(window.location.href).searchParams.get("amount");

    // 토스 시크릿 키
    const secretKey = process.env.REACT_APP_TOSS_SECRET_KEY;

    useEffect(() => {
        // 결제 승인 요청 시 필요한 파라미터
        const successParam = {
            orderId: orderId,
            paymentKey: paymentKey,
            amount: amount
        };

        // 결제 승인 요청
        axios({
            method: `post`,
            url: `https://api.tosspayments.com/v1/payments/confirm`,
            data: successParam,
            headers: {
                "Authorization": `Basic ${base64.encode(secretKey+":")}`,
                "Content-Type": `application/json`
            }
        })
            .then((res) => {

                if (res.status == 200) {
                    // 성공 시 DB 저장 후 페이지 이동
                    const response = res.data;
                    const data = {
                        mId: response.mId, // 상점 아이디 최대 14자
                        lastTransactionKey: response.lastTransactionKey, // 마지막 거래 고유키. 결제 한 건에 대한 승인 거래와 취소 거래를 구분하는데 사용. 예를 들어 결제 승인 후 부분 취소를 두 번 했다면 마지막 부분 취소 거래 건에 대한 키 값이 할당. 최대 64자.
                        paymentKey: response.paymentKey, // 결제 고유키 최대 200자
                        orderId: response.orderId, // 상점에서 주문 건을 구분하기 위해 발급한 고유 ID 6~64자
                        orderName: response.orderName, // 결제에 대한 주문명 최대 100자
                        taxExemptionAmount: response.taxExemptionAmount, // integer 과세 제외 금액
                        status: response.status, // 상태값 (READY: 준비됨, IN_PROGRESS: 진행중, WAITING_FOR_DEPOSIT: 가상계좌 입금 대기 중, DONE: 결제 완료됨, CANCELED: 결제가 취소됨, PARTIAL_CANCELED: 결제가 부분 취소됨,
                                                 // ABORTED: 카드 자동 결제 혹은 키인 결제를 할 때 결제 승인에 실패함, EXPIRED: 유효 시간(30분)이 지나 거래가 취소됨)
                        requestedAt: response.requestedAt, // 결제요청 날짜와시간
                        approvedAt: response.approvedAt, // 결제승인 날짜와시간
                        useEscrow: response.useEscrow, // boolean 에스크로 사용 여부
                        cultureExpense: response.cultureExpense, // boolean 문화비(도서, 공연 티켓, 박물관·미술관 입장권 등) 지출 여부
                        card_amount: (response.card != null)? response.card.amount : null, // number 카드 결제 - 카드로 결제한 금액
                        card_issuerCode: (response.card != null)? response.card.issuerCode : null, // 카드 결제 - 카드 발급사 코드
                        card_acquirerCode: (response.card != null)? response.card.acquirerCode : null, // 카드 결제 - 카드 매입사 코드
                        card_number: (response.card != null)? response.card.number : null, // 카드 결제 - 카드번호 최대 20자
                        card_installmentPlanMonths: (response.card != null)? response.card.installmentPlanMonths : null, // integer 카드 결제 - 할부개월수
                        card_approveNo: (response.card != null)? response.card.approveNo : null, // 카드 결제 - 카드사승인번호 최대 8
                        card_useCardPoint: (response.card != null)? response.card.useCardPoint : null, // boolean 카드 결제 - 카드사포인트사용여부
                        card_cardType: (response.card != null)? response.card.cardType : null, // 카드 결제 - 카드 종류 (신용, 체크, 기프트)
                        card_ownerType: (response.card != null)? response.card.ownerType : null, // 카드 결제 - 카드 소유자타입 (개인, 법인)
                        card_acquireStatus: (response.card != null)? response.card.acquireStatus : null, // 카드 결제 - 카드 결제 매입 상태 (READY - 매입 대기, REQUESTED - 매입 요청됨, COMPLETED - 매입 완료, CANCEL_REQUESTED - 매입 취소 요청됨, CANCELED - 매입 취소 완료)
                        card_isInterestFree: (response.card != null)? response.card.isInterestFree : null, // boolean 카드 결제 - 무이자할부적용여부
                        card_interestPayer: (response.card != null)? response.card.interestPayer : null, // 카드 결제 - 무이자할부 시 할부수수료 부담 주체 (BUYER - 상품을 구매한 고객, CARD_COMPANY - 카드사, MERCHANT - 상점)
                        virtual_accountType: (response.virtualAccount != null)? response.virtualAccount.accountType:null, // 가상계좌 - 가상계좌 타입 (일반, 고정)
                        virtual_accountNumber: (response.virtualAccount != null)? response.virtualAccount.accountNumber:null, // 가상계좌 - 계좌번호 최대 20자
                        virtual_bankCode: (response.virtualAccount != null)? response.virtualAccount.bankCode:null, // 가상계좌 - 가상계좌 발급한 은행 코드
                        virtual_customerName: (response.virtualAccount != null)? response.virtualAccount.customerName:null, // 가상계좌 - 고객 이름 최대 100자
                        virtual_dueDate: (response.virtualAccount != null)? response.virtualAccount.dueDate:null, // 가상계좌 - 입금기한
                        virtual_refundStatus: (response.virtualAccount != null)? response.virtualAccount.refundStatus:null, // 가상계좌 - 환불처리상태 (NONE - 해당 없음, FAILED - 환불 실패, PENDING - 환불 처리중, PARTIAL_FAILED - 부분환불 실패, COMPLETED - 환불 완료)
                        virtual_expired: (response.virtualAccount != null)? response.virtualAccount.expired:null, // boolean 가상계좌 - 가상계좌만료여부
                        virtual_settlementStatus: (response.virtualAccount != null)? response.virtualAccount.settlementStatus:null, // 가상계좌 - 정산상태 정산이 아직 되지 않았다면 INCOMPLETE, 정산이 완료됐다면 COMPLETE
                        transfer_bankCode: (response.transfer != null)? response.transfer.bankCode:null, // 계좌이체 - 은행 코드
                        transfer_settlementStatus: (response.transfer != null)? response.transfer.settlementStatus:null, // 계좌이체 - 정산상태 정산이 아직 되지 않았다면 INCOMPLETE, 정산이 완료됐다면 COMPLETE
                        // cashReceipt_receiptKey: (response.cashReceipt != null)? response.cashReceipt.receiptKey:"", // 현금영수증 키값
                        // cashReceipt_type: (response.cashReceipt != null)? response.cashReceipt.type:"", // 현금영수증 종류 (소득공제, 지출증빙)
                        // cashReceipt_amount: (response.cashReceipt != null)? response.cashReceipt.amount:"", // 현금영수증 처리된 금액
                        // cashReceipt_taxFreeAmount: (response.cashReceipt != null)? response.cashReceipt.taxFreeAmount:"", // 면세 처리된 금액
                        // cashReceipt_issueNumber: (response.cashReceipt != null)? response.cashReceipt.issueNumber:"", // 현금영수증 발급 번호
                        // cashReceipt_receiptUrl: (response.cashReceipt != null)? response.cashReceipt.receiptUrl:"", // 발행된 현금영수증 확인할 수 있는 주소
                        discount_amount: (response.discount != null)? response.discount.amount:null, // 카드사 즉시할인 프로모션 - 적용한 금액
                        cancels_cancelAmount: (response.cancels != null)? response.cancels.cancelAmount:null, // number 결제 취소 금액
                        cancels_cancelReason: (response.cancels != null)? response.cancels.cancelReason:null, // 결제 취소 사유 최대 200자
                        cancels_taxFreeAmount: (response.cancels != null)? response.cancels.taxFreeAmount:null, // number 취소 금액 중 면세 금액
                        cancels_taxExemptionAmount: (response.cancels != null)? response.cancels.taxExemptionAmount:null, // number 과세 제외 금액
                        cancels_refundableAmount: (response.cancels != null)? response.cancels.refundableAmount:null, // number 결제 취소 후 환불 가능한 잔액
                        cancels_canceledAt: (response.cancels != null)? response.cancels.canceledAt:null, // 결제취소일시
                        cancels_transactionKey: (response.cancels != null)? response.cancels.transactionKey:null, // 결제 취소 고유키 최대 64자
                        secret: response.secret, // 가상계좌 웹훅 요청이 정상적인 요청인지 검증하기 위한 값입니다. 이 값이 가상계좌 웹훅 이벤트 본문으로 돌아온 secret과 같으면 정상적인 요청입니다. 최대 길이는 50자 이하.
                        type: response.type, // 결제 타입 정보 NORMAL(일반 결제), BILLING(자동 결제), BRANDPAY(브랜드페이)
                        isPartialCancelable: response.isPartialCancelable, // boolean 부분취소 가능여부 - false
                        receipt_url: (response.receipt != null)?response.receipt.url:null, // 발행된 영수증 정보
                        checkout_url: (response.checkout != null)?response.checkout.url:null, // 결제창 정보
                        currency: response.currency, // 통화단위 KRW 원화만 사용
                        totalAmount: response.totalAmount, // number 총 결제 금액
                        balanceAmount: response.balanceAmount, // number 취소할 수 있는 금액(잔고)
                        suppliedAmount: response.suppliedAmount, // number 공급가액
                        vat: response.vat, // number 부가세입니다. (결제 금액 amount - 면세 금액 taxFreeAmount) / 11 후 소수점 첫째 자리에서 반올림해서 계산합니다. (e.g. 결제 금액이 10,000원이고, 면세 금액이 3,000원이라면 부가세는 (10000-3000)/11 = 636.3636..을 반올림한 값 636원입니다.)
                        taxFreeAmount: response.taxFreeAmount, // number 면세금액
                        method: response.method, // 결제수단 (카드, 가상계좌, 휴대폰, 계좌이체, 상품권(문화상품권, 도서문화상품권, 게임문화상품권), 간편결제)
                        version: response.version // Payment 객체의 응답 버전
                    };

                    // 결제 완료 시 결제 정보 업데이트 후 결제 완료 페이지 이동
                    // 저장 후 인덱스 받아서 이동
                    Axios.patch("/user/payment/update" , data , true).then(result => {
                        // 수기결제에서 진행한 결제의 경우 수기 결제 리스트로 이동
                        if(result.notes_payment == 'Y'){
                            navigate("/user/notesPaymentList");
                        } else {
                            navigate("/PurchaseCourse/3/" + result.order_idx , { state : result.order_idx });
                        }
                    })
                } else {
                    navigate('/payment/fail');
                }
            })
            .catch((err) => {
                console.log(err);
                navigate('/payment/fail');
            })
    }, [])

    return (
        <>
        </>
    )
};

export default Success;
