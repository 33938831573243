import {useNavigate} from "react-router-dom";
import {showMenuModal} from "../../store/reducers/menuModalReducer";
import {useDispatch} from "react-redux";
import useUser from "../../Hooks/useUser";
import {activeUserToken, deleteUserToken} from "../../store/reducers/userGuardReducer";
import {showAlert} from "../../store/reducers/alertDialogSlideRedux";


const Header = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {isToken , name , email , token} = useUser();

    const alertNavigateLogin = () => {
        dispatch(
            showAlert({
                items: 1,
                title: '로그인 필수 화면입니다.',
                message: '로그인 후 이용해주세요',
                sucCallback: () => {
                    navigate("/login")
                },
                text1: '확인',
            }),
        );
    }

    return (
        <header id="header" className="header">
            <div className="inner">
                <div className="site-nav-action">
                    <div className="menu" onClick={ ()=> {
                        dispatch(
                            showMenuModal()
                        )
                    }}>
                        <div className="menu-icon">
                            <div className="menu-line menu-line1"></div>
                            <div className="menu-line menu-line2"></div>
                            <div className="menu-line menu-line3"></div>
                        </div>
                    </div>
                </div>
                <h1 className="logo">
                    <span className="blind">루시맥스</span>
                    <a href="/">
                        <img src="/images/logo@2x.png" alt="루시맥스"/>

                    </a>
                </h1>
                <div className="top-nav">
                    <h2 className="blind">전체메뉴</h2>
                    <ul className="gnb">
                        <li className="on">
                            <a className="anchor-link" href="/#st02">수업 모습</a>
                        </li>
                        <li className="">
                            <a className="anchor-link" href="/#st04">수업 순서</a>
                        </li>
                        <li className="">
                            <a className="anchor-link" href="/#st06">줌설치 방법</a>
                        </li>
                        <li className="">
                            <a className="anchor-link" href="/#st07">줌수업 참가</a>
                        </li>
                    </ul>
                    <div className="btn-group-box">
                        {isToken ?
                            <div className="group group-line">
                                <button type="button" className="btn btn-text btn-logout" onClick={()=> {
                                    dispatch(
                                        deleteUserToken()
                                    )
                                    navigate("/" , {replace:true})
                                }
                                }>
                                    <img className="icon" src="/images/icon/icon_logout.svg" alt=""/>
                                    <span className="txt">LOGOUT</span>
                                </button>
                                <span className="bar"></span>
                                <button onClick={()=> {navigate("/user/mypage" , {replace:true})}} className="btn btn-text btn-mypage">
                                    <span className="txt">마이페이지</span>
                                </button>
                            </div>
                            :
                            <div className="group group-line">
                                <button className="btn btn-text btn-login" onClick={() => {navigate('/login')}}>
                                    <img className="icon" src="/images/icon/icon_login.svg" alt=""/>
                                    <span className="txt">LOGIN</span>
                                </button>
                                <span className="bar"></span>
                                <a onClick={()=>{navigate("/registry")}} className="btn btn-text btn-join" >
                                    <span className="txt">회원가입</span>
                                </a>
                            </div>
                        }
                        <div className="group">
                            <button onClick={()=> { isToken ? navigate('/PurchaseCourse/1') : alertNavigateLogin()}} className="btn btn-buy">
                                <img className="icon" src="/images/icon/icon_coupon.svg" alt="" />
                                <span className="txt">수강권 구매</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;