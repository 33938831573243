import {createAction, handleActions} from 'redux-actions';

const NEXT = 'paymentCurriculumReducer/NEXT';
const BACK = 'paymentCurriculumReducer/BACK';

export const nextAction = createAction(NEXT, (options) => options);
export const backAction = createAction(BACK);

const initialState = {
    price: 0,
    curriculumTime: null,
    curriculumDay: null,
    step: 1
};

export default handleActions(
    {
        [NEXT]: (state, action) => {
            return {
                ...initialState,
                ...action.payload,
            }
        },
        [BACK]: (state) => ({
            ...initialState,
            ...state,
        }),
    },
    initialState,

);
