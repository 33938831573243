
const St07 = () => {
    return (
        <section id="st07" className="section section-zoomJoin">
            <div className="section-inner">
                <div className="section-title">
                    <h3 className="st-tit st07-tit">
                        <img src="/images/pc/img_st07_tit@2x.png" alt="" />
                            <span className="blind">줌 수업 참가하기</span>
                    </h3>
                </div>
                <div className="section-content">
                    <div className="d-flex col4">
                        <div className="item">
                            <picture>
                                <source media="(max-width: 1024px)" srcSet="/images/mo/img_st07_cont01@2x.png" />
                                    <source media="(min-width: 1025px)" srcSet="/images/pc/img_st07_cont01@2x.png" />
                                        <img src="/images/pc/img_st07_cont01@2x.png" alt="PC에 설치된 줌 아이콘을 클릭" />
                            </picture>
                        </div>
                        <div className="item">
                            <picture>
                                <source media="(max-width: 1024px)" srcSet="/images/mo/img_st07_cont02@2x.png" />
                                    <source media="(min-width: 1025px)" srcSet="/images/pc/img_st07_cont02@2x.png" />
                                        <img src="/images/pc/img_st07_cont02@2x.png" alt="회의 참가 클릭" />
                            </picture>
                        </div>
                        <div className="item">
                            <picture>
                                <source media="(max-width: 1024px)" srcSet="/images/mo/img_st07_cont03@2x.png" />
                                    <source media="(min-width: 1025px)" srcSet="/images/pc/img_st07_cont03@2x.png" />
                                        <img src="/images/pc/img_st07_cont03@2x.png"
                                             alt="루스맥스에서 보내주는 아이디와 참가자 이름 입력. 이름 입력 시 이후 회의에서 내 이름 기억 체크 권장" />
                            </picture>
                        </div>
                        <div className="item">
                            <picture>
                                <source media="(max-width: 1024px)" srcSet="/images/mo/img_st07_cont04@2x.png" />
                                    <source media="(min-width: 1025px)" srcSet="/images/pc/img_st07_cont04@2x.png" />
                                        <img src="/images/pc/img_st07_cont04@2x.png" alt="루스맥스에서 보내주는 비밀번호 입력 후 수업 참가" />
                            </picture>
                        </div>
                    </div>
                    <div className="txt-info type-red">
                        <p>* 아이디와 비밀번호는 별도로 보내드리고, 고정 아이디 및 고정 비밀번호이므로 첫 번째 수업 이후에도 동일한 아이디와 비번을 사용하면 됩니다.</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default St07;