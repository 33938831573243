import UserGuard from "../Guard/UserGuard";
import Loadable from "../Component/Loadable";
import {lazy} from "react";
import FindPassword from "../Pages/FindPassword";
import FindEmail from "../Pages/FindEmail";
import SuccessFindEmail from "../Pages/SuccessFindEmail";

const MyPageLazy = Loadable(lazy(() => import("../Pages/mypage/MyPage")));
const PaymentListLazy = Loadable(lazy(() => import("../Pages/mypage/PaymentList")));
const NotesPaymentListLazy = Loadable(lazy(() => import("../Pages/mypage/NotesPaymentList")));
const UsedCurriculumLazy = Loadable(lazy(() => import("../Pages/mypage/UsedCurriculum")));


const MyPageRoutes = {
    path: '/user',
    children: [
        {
            path: 'mypage',
            element: (
                <UserGuard>
                    <MyPageLazy/>
                </UserGuard>
            ),
        },
        {
            path: 'findPassword',
            element: (
               <FindPassword />
            ),
        },
        {
            path: 'findEmail',
            element: (
                <FindEmail />
            ),
        },
        {
            path: 'successFindEmail/:email',
            element: (
                <SuccessFindEmail />
            ),
        },
        {
            path: 'paymentList',
            element: (
                <UserGuard>
                    <PaymentListLazy/>
                </UserGuard>
            ),
        },
        {
            path: 'notesPaymentList',
            element: (
                <UserGuard>
                    <NotesPaymentListLazy/>
                </UserGuard>
            ),
        },
        {
            path: 'usedCurriculum',
            element: (

                    // <UsedCurriculum>
                    //     <UserGuard />
                    // </UsedCurriculum> 이렇게 하면 페이지 로딩됨.
                    // <UserGuardLazy>
                    //     <UsedCurriculum />
                    // </UserGuardLazy> 순서 안바뀜
                    <UserGuard>
                        <UsedCurriculumLazy />
                    </UserGuard>
            ),
        },
    ]
}

export default MyPageRoutes;