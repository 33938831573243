import {createAction, handleActions} from 'redux-actions';

const SHOW = 'alertMenuModalRedux/SHOW';
const HIDE = 'alertMenuModalRedux/HIDE';

export const showMenuModal = createAction(SHOW);
export const hideMenuModal = createAction(HIDE);

const initialState = {
    isShow: false
};

export default handleActions(
    {
        [SHOW]: (state, action) => {
            return {
                ...state,
                isShow: true
            }
        },
        [HIDE]: (state) => {
            return {
                ...state,
                isShow: false
            }
        },
    },
    initialState,

);
