import UserGuard from "../Guard/UserGuard";
import Loadable from "../Component/Loadable";
import {lazy} from "react";

const PurchaseCourseLazy = Loadable(lazy(() => import("../Pages/purchase_course")));
const PurchaseCourseTwoLazy = Loadable(lazy(() => import("../Pages/purchase_course/PurchaseCourseTwo")));
const PurchaseCourseThreeLazy = Loadable(lazy(() => import("../Pages/purchase_course/PurchaseCourseThree")));
const PurchaseCourseRoutes = {
    path: '/PurchaseCourse',
    children: [
        {
            path: '1',
            element: (
                <UserGuard>
                    <PurchaseCourseLazy />
                </UserGuard>
            )
        },
        {
            path: '2',
            element: (
                <UserGuard>
                    <PurchaseCourseTwoLazy />
                </UserGuard>
            )
        },
        {
            path: '3/:idx',
            element: (
                <UserGuard>
                    <PurchaseCourseThreeLazy />
                </UserGuard>
            )
        },
    ]
}

export default PurchaseCourseRoutes;