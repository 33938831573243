
const St09 = () => {
    return (
        <section id="st09" className="section section-compare">
            <div className="section-inner">
                <div className="section-title">
                    <h3>
                        <picture className="st-tit st09-tit">
                            <source media="(max-width: 1024px)" srcSet="/images/mo/img_st09_tit@2x.png" />
                                <source media="(min-width: 1025px)" srcSet="/images/pc/img_st09_tit@2x.png" />
                                    <img src="/images/pc/img_st09_tit@2x.png" alt="4스텝 파닉스 줌수업 & 유튜브로 자기주도 학습 차이" />
                        </picture>
                        <span className="blind">4스텝 파닉스 줌수업 & 유튜브로 자기주도 학습 차이</span>
                    </h3>
                </div>
                <div className="section-content">
                    <div className="table-wrap">
                        <table className="table type-primary is-fixed">
                            <caption>4스텝 파닉스 줌수업과 유튜브 자기주도 학습 차이</caption>
                            <colgroup>
                                <col className="col-220" />
                                    <col className="col-auto" />
                                        <col className="col-auto" />
                            </colgroup>
                            <thead>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col">줌수업</th>
                                <th scope="col">유튜브 자기주도</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <th scope="row">원어민 동영상</th>
                                <td>
                                    - 전체 영상 (풀영상) <br />
                                    - 한글 자막이 큰 글씨로 보임
                                </td>
                                <td>
                                    - 일부 퀴즈 문항이 영상에서 제외된 채 업로드<br />
                                    - 유튜브 자막 기능으로 한국어 선택 가능
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">수업 진행</th>
                                <td>
                                    한국인 강사가 원어민 동영상을 활용
                                </td>
                                <td>
                                    동영상으로만 학습
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">추가 수업 도구</th>
                                <td>
                                    다량의 이미지 파일을 영상과 함께 사용
                                </td>
                                <td>
                                    없음
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">숙제 검사</th>
                                <td>
                                    한국인 강사가 교재 및 앱학습 검사
                                </td>
                                <td>
                                    스스로 체크
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">꾸준함</th>
                                <td>
                                    주 3회 (또는) 2회 실시간 수업 참여
                                </td>
                                <td>
                                    자율학습
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">6개월 후</th>
                                <td>
                                    파닉스 완성
                                </td>
                                <td>
                                    개인마다 차이
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default St09;