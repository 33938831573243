import {useRoutes} from 'react-router-dom'
import MainRoutes from "./MainRoutes";
import MissingRoutes from "./MissingRoutes";
import LoginRoutes from "./LoginRoutes";
import RegistryRoutes from "./RegistryRoutes";
import PurchaseCourseRoutes from "./PurchaseCourseRoutes";
import MyPageRoutes from "./MyPageRoutes";
import PaymentRoutes from "./PaymentRoutes";

const Routes = () => {
    return useRoutes([
        MyPageRoutes,
        MainRoutes,
        LoginRoutes,
        MissingRoutes,
        RegistryRoutes,
        PurchaseCourseRoutes,
        PaymentRoutes
    ]);
}

export default Routes;