import Success from "../Pages/payment/Success";
import Fail from "../Pages/payment/Fail";

const PaymentRoutes = {
    path: '/payment',
    children: [
        {
            path: 'success',
            element: <Success/>,
        },
        {
            path: 'fail',
            element: <Fail/>,
        },
    ]
}

export default PaymentRoutes;