
const Footer = () => {
    return (
        <div className="footer">
            <div className="inner">
                <div className="foot-info">
                    <p className="txt">
                        <span className="company">㈜루시맥스</span>
                        <span className="no-bd">대표이사: 김상규</span><br className="m-visible"/>
                        <span>경기도 용인시 수지구 풍덕천로 129번길 9 오성빌딩 5층</span><br className="m-visible"/>
                        <span>Tel: 070-8772-0707</span><br/>
                        <span className="no-bd">사업자 등록번호: 142-81-76273</span><br className="m-visible"/>
                        <span>통신판매번호: 2015-용인수지-497</span><br className="m-visible"/>
                        <span>개인정보책임관리자: lucymaxkorea@naver.com</span>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Footer;