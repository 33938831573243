import {combineReducers} from "redux";
import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import userGuardReducer from "./userGuardReducer";
import alertDialogSlideRedux from "./alertDialogSlideRedux";
import menuModalReducer from "./menuModalReducer";
import paymentCurriculumReducer from "./paymentCurriculumReducer";

const persistConfig = {
    key: "root1",
    storage,
    whitelist: ["userGuardReducer" , "menuModalReducer"]
}

const reducers = combineReducers({
    userGuardReducer , alertDialogSlideRedux , menuModalReducer , paymentCurriculumReducer
})

export default persistReducer(persistConfig , reducers);