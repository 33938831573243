
const St01 = ({youtubeFallow}) => {
    return (
        <section id="st01" className="section section-visual">
            <div className="section-inner">
                {/*<picture className="st01-brief">*/}
                {/*    <source media="(max-width: 1024px)" srcSet="/images/mo/img_st01_brief@2x.png" />*/}
                {/*        <source media="(min-width: 1025px)" srcSet="/images/pc/img_st01_brief@2x.png" />*/}
                {/*            <img src="/images/pc/img_st01_brief@2x.png" alt="선착순 20명 첫 2개월 50% 할인" />*/}
                {/*</picture>*/}
                <p className="st01-txt01">
                    <picture className="image">
                        <source media="(max-width: 1024px)" srcSet="/images/mo/img_st01_txt01@2x.png"/>
                            <source media="(min-width: 1025px)" srcSet="/images/pc/img_st01_txt01@2x.png"/>
                                <img src="/images/pc/img_st01_txt01@2x.png"
                                     alt="영어학원 분위기 그대로 집에서 또래 친구들과 1:5로 수업하는 4스텝 파닉스 화상 영어"/>
                    </picture>
                    <span className="text">(4스텝 파닉스 유튜브 구독자: {youtubeFallow.fallow})
                        <br/>
                        <div style={{marginTop: 15 , fontWeight: "bold"}}>수업 1 회 무료 체험 - 전화 또는 카톡으로 신청해주세요</div>
                    </span>

                </p>
                <h3>
                    <span className="st01-txt02">
                        <img src="/images/pc/img_st01_txt02@2x.png"
                             alt="4스텝 파닉스 & 어린이 기초 영어회화 실시간 줌수업으로 파닉스, 기초영어회화 6개월 완성"/>
                    </span>
                    <span className="blind">
                        4스텝 파닉스 & 어린이 기초 영어회화 실시간 줌수업으로 파닉스, 기초영어회화 6개월 완성
                    </span>
                </h3>
                <picture className="st01-cont01">
                    <source media="(max-width: 1024px)" srcSet="/images/mo/img_st01_cont01@2x.png" />
                    <source media="(min-width: 1025px)" srcSet="/images/pc/img_st01_cont01@2x.png" />
                    <img src="/images/pc/img_st01_cont01@2x.png" alt="주 3회 수업기준, 한국인 온라인 강사가 원어민 동영상 강의를 활용해서 학습자 아이들과 적극적으로 상호작용 하면서 줌으로 실시간 수업 진행. 듣기, 읽기, 말하기, 쓰기, 발음 동시 향상!" />
                </picture>
                <p className="st01-cont02">4스텝 파닉스 1:5(학습자 최대 인원) <br className="m-visible" /> 화상 수업 장점</p>

                <div className="st01-el01">
                    <img src="/images/pc/img_st01_el01@2x.png" alt="apple" />
                </div>
                <div className="st01-el02">
                    <img src="/images/pc/img_st01_el02@2x.png" alt="bed" />
                </div>
                <div className="st01-el03">
                    <img src="/images/pc/img_st01_el03@2x.png" alt="cat" />
                </div>
            </div>
        </section>
    );
}

export default St01;