
import axios from "axios";
import {showAlert} from "../store/reducers/alertDialogSlideRedux";
import {store} from "../store";
import {deleteUserToken} from "../store/reducers/userGuardReducer";


const instance = axios.create({
    baseURL: process.env.REACT_APP_REST_URL,
    timeout: 1000 * 10,
    // validateStatus: function (status) {
    //     return status >= 200 && status < 300;
    // },
    headers: { 'Cache-Control': 'no-cache' }
})

export const initData = (dispatch , navigate) => {
    instance.interceptors.request.use(
        async (config) => {
            const token = await store.getState().userGuardReducer.token;
            if (token) {
                //config.headers["Authorization"] = token
                config.headers.Authorization = token
            }
            return config;
        },
        async (error) => {
            return Promise.reject(error);
        }
    );

    instance.interceptors.response.use(
        async (response) => {
            if(response.data.code !== 200) {
                return failed(new Error(
                    JSON.stringify({
                        response: {
                            status: response.data.code,
                            data: response.data.message
                        }
                    })), dispatch);
            }

            return response.data.data;
        },
        async (error) => {
            if (error.response != null) {
                return failed(error, dispatch , navigate);
            } else {
                dispatch(
                    showAlert({
                        items: 1,
                        title: '요청 실패',
                        message: error.message,
                        text1: '닫기'
                    }),
                );
                return Promise.reject(error);
            }
        }
    );
}

const Axios = async (method , url , param , multipartUse) =>{

    let config = {
        method: method,
        url: url,
        timeout: 1000 * 10,
    }

    multipartUse ? config.headers = {'Content-Type': "multipart/form-data; charset=utf-8"} :  config.headers = {'Content-Type': "application/json; charset=utf-8"};

    (method === 'get' || method === 'delete') ? config.params = param : config.data = param;

    return await instance(config);
}

const failed = (error , dispatch , navigate) => {
    if(error.response == null) {
        error = JSON.parse(error.message)
    }

    const code = error.response.status;
    const message = error.response.data;
    switch (code) {
        case 401: {
            dispatch(
                showAlert({
                    items: 1,
                    message: '서버와의 통신을 실패 했습니다.\n고객센터에 문의해 주세요.',
                    text1: '확인',
                }),
            );
            break
        }
        case 404: {
            dispatch(
                showAlert({
                    items: 1,
                    title: '권한 확인 필요',
                    message: message,
                    text1: '닫기',
                }),
            );
            break
        }
        case 403: {
            dispatch(
                showAlert({
                    items: 1,
                    title: '잘못된 요청입니다.',
                    message: message,
                    text1: '닫기',
                }),
            );
            break
        }
        case 400: {
            dispatch(
                showAlert({
                    items: 1,
                    title: '실패',
                    message: message,
                    text1: '닫기',
                }),
            );
            break
        }
        case 301: {
            dispatch(
                showAlert({
                    items: 1,
                    title: '실패',
                    message: message,
                    text1: '닫기',
                    sucCallback: ()=> {
                        dispatch(
                            deleteUserToken()
                        )
                        navigate("/login" , {replace:true})
                    }
                }),
            );
            break
        }
        default: {
            dispatch(
                showAlert({
                    items: 1,
                    title: '서버 응답이 없습니다.\n고객센터에 문의해 주세요.',
                    message: message,
                    text1: '닫기',
                }),
            );
        }
    }
    return Promise.reject(error);
}

const request = {
    get: (url, params, multipartUse) => {
        return Axios('get', url, params , multipartUse );
    },
    post: (url, params, multipartUse) => {
        return Axios('post', url, params , multipartUse);
    },
    patch: (url, params, multipartUse) => {
        return Axios('patch', url, params , multipartUse);
    },
    delete: (url, params, multipartUse) => {
        return Axios('delete', url, params , multipartUse);
    }
};

export default request
