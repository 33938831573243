import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import {useDispatch, useSelector} from "react-redux";
import {useCallback} from "react";
import {hideAlert} from "../store/reducers/alertDialogSlideRedux";





const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});



const AlertDialog = () => {

    const {
        isShow,
        title,
        items,
        message,
        text1,
        text2,
        sucCallback,
        failCallback
    } = useSelector((state) => state.alertDialogSlideRedux);

    // Redux Dispatch
    const dispatch = useDispatch();

    const failOnClick = useCallback(() => {
        dispatch(hideAlert());
        if (failCallback && typeof failCallback === 'function') {
            failCallback();
        }
    }, [dispatch, failCallback]);


    const sucOnClick = useCallback(() => {
        dispatch(hideAlert());
        if (sucCallback && typeof sucCallback === 'function') {
            sucCallback();
        }
    }, [dispatch, sucCallback]);

    // 배경 클릭 핸들러
    const onHide = useCallback(() => {
        dispatch(hideAlert());
    }, [dispatch]);

    return (
        <div>
            <Dialog
                open={isShow}
                TransitionComponent={Transition}
                keepMounted
                onClose={onHide}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button type="button"
                            onClick={ sucOnClick }>{text1}</Button>
                    {items === 2 &&
                        <Button type="button"
                                onClick={ failOnClick }>{text2}</Button>
                    }
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default AlertDialog;