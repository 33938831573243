

const St05 = () => {
    return (
        <section id="st05" className="section section-improve">
            <div className="section-inner">
                <div className="section-title">
                    <h3 className="st-tit st05-tit">
                        <img src="/images/pc/img_st05_tit@2x.png" alt=""/>
                            <span className="blind">영어 영역 향상</span>
                    </h3>
                </div>
                <div className="section-content">
                    <div className="table-wrap">
                        <table className="table type-primary is-fixed">
                            <caption>영어 영역 향상</caption>
                            <colgroup>
                                <col className="col-220"/>
                                    <col className="col-auto"/>
                                        <col className="col-290"/>
                            </colgroup>
                            <thead>
                            <tr>
                                <th scope="col">향상 영역</th>
                                <th scope="col">이유(학습 도구)</th>
                                <th scope="col">해당 과목</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>
                                    듣기
                                </td>
                                <td>
                                    원어민 동영상, 앱 퀴즈
                                </td>
                                <td>
                                    파닉스, 기초영어회화
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    읽기
                                </td>
                                <td>
                                    줌수업, 원어민 동영상, 앱 퀴즈, 교재
                                </td>
                                <td>
                                    파닉스, 기초영어회화
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    말하기
                                </td>
                                <td>
                                    줌수업, 원어민 동영상
                                </td>
                                <td>
                                    파닉스, 기초영어회화
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    쓰기
                                </td>
                                <td>
                                    교재
                                </td>
                                <td>
                                    파닉스
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    발음
                                </td>
                                <td>
                                    원어민 동영상, 앱 퀴즈
                                </td>
                                <td>
                                    파닉스, 기초영어회화
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default St05;