
const St10 = () => {
    return (
        <section id="st10" className="section section-review">
            <div className="section-inner">
                <div className="section-title">
                    <picture className="st-tit st10-tit">
                        <source media="(max-width: 1024px)" srcSet="/images/mo/img_st10_tit@2x.png" />
                        <source media="(min-width: 1025px)" srcSet="/images/pc/img_st10_tit@2x.png" />
                        <img src="/images/pc/img_st10_tit@2x.png"
                             alt="4스텝 파닉스 화상 수업 체험 사례. 아래 사례를 클릭하면 학부모 체험을 읽을수 있습니다." />
                    </picture>
                </div>
                <div className="section-content">
                    <div className="review-lists">
                        <div className="review-item">
                            <a href="https://blog.naver.com/ningss01/223062166271" target="_blank">
                                <div className="review-item__badge">
                                    <span className="num">사례 01</span>
                                    <span className="rept">6개월 학습 사례</span>
                                </div>
                                <div className="review-item__title">
                                    초등영어 홈스쿨링 4STEP PHONICS 화상영어 6개월 후기
                                </div>
                            </a>
                        </div>
                        <div className="review-item">
                            <a href="https://blog.naver.com/haosth/223055657492" target="_blank">
                                <div className="review-item__badge">
                                    <span className="num">사례 02</span>
                                    <span className="rept">6개월 학습 사례</span>
                                </div>
                                <div className="review-item__title">
                                    초등화상영어 루시맥스 6개월차 후기, 집에서 파닉스 + 영단어 공부하기
                                </div>
                            </a>
                        </div>
                        <div className="review-item">
                            <a href="https://blog.naver.com/stotoro82/223056194299" target="_blank">
                                <div className="review-item__badge">
                                    <span className="num">사례 03</span>
                                    <span className="rept">6개월 학습 사례</span>
                                </div>
                                <div className="review-item__title">
                                    유아,초등 화상영어 (4step 파닉스,루시맥스) 5개월 후기~!
                               </div>
                            </a>
                        </div>

                        <div className="review-item">
                            <a href="https://blog.naver.com/ningss01/223020993030" target="_blank">
                                <div className="review-item__badge">
                                    <span className="num">사례 04</span>
                                    <span className="rept">4개월 학습 사례</span>
                                </div>
                                <div className="review-item__title">
                                    초등영어 공부 4스텝 파닉스 줌영어수업 4개월 후기
                                </div>
                            </a>
                        </div>
                        <div className="review-item">
                            <a href="https://blog.naver.com/sh841109/223021667930" target="_blank">
                                <div className="review-item__badge">
                                    <span className="num">사례 05</span>
                                    <span className="rept">4개월 학습 사례</span>
                                </div>
                                <div className="review-item__title">
                                    1학년 화상영어 수업 4스텝으로 4개월째 학습
                                </div>
                            </a>
                        </div>
                        <div className="review-item">
                            <a href="https://blog.naver.com/sh841109/222909056490" target="_blank">
                                <div className="review-item__badge">
                                    <span className="num">사례 06</span>
                                </div>
                                <div className="review-item__title">
                                    파닉스 화상영어 4스텝으로 시작했어요
                                </div>
                            </a>
                        </div>
                        <div className="review-item">
                            <a href="https://blog.naver.com/ran_1124/222912238571" target="_blank">
                                <div className="review-item__badge">
                                    <span className="num">사례 07</span>
                                </div>
                                <div className="review-item__title">
                                    초등영어 홈스쿨링 원어민 수업으로<br/>파닉스부터 재미있게 화상영어로!
                                </div>
                            </a>
                        </div>
                        <div className="review-item">
                            <a href="https://blog.naver.com/stotoro82/222914675232" target="_blank">
                                <div className="review-item__badge">
                                    <span className="num">사례 08</span>
                                </div>
                                <div className="review-item__title">
                                    추천! 7살 파닉스 화상영어 (4스텝 파닉스,루시맥스)
                                </div>
                            </a>
                        </div>
                        <div className="review-item">
                            <a href="https://blog.naver.com/haosth/222915718134" target="_blank">
                                <div className="review-item__badge">
                                    <span className="num">사례 09</span>
                                </div>
                                <div className="review-item__title">
                                    파닉스 화상영어 시작,<br/>
                                    영어 홈스쿨링이 부담스러우신 분들에게 추천!
                                </div>
                            </a>
                        </div>
                        <div className="review-item">
                            <a href="https://blog.naver.com/bright_sweet/222915751038" target="_blank">
                                <div className="review-item__badge">
                                    <span className="num">사례 10</span>
                                </div>
                                <div className="review-item__title">
                                    [ 4스텝 파닉스 ] 화상영어<br/>
                                    엄마표 영어 홈스쿨링 파닉스 기초영어회화 초등영어 추천
                                </div>
                            </a>
                        </div>
                        <div className="review-item">
                            <a href="https://blog.naver.com/ningss01/222916058882" target="_blank">
                                <div className="review-item__badge">
                                    <span className="num">사례 11</span>
                                </div>
                                <div className="review-item__title">
                                    초등영어 홈스쿨링 4스텝 파닉스 화상영어와 함께
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default St10;