
const St08 = ({youtubeFallow}) => {
    return (
        <section id="st08" className="section section-classInfo">
            <div className="section-inner">
                <div className="section-title">
                    <h3 className="st-tit st08-tit">
                        <img src="/images/pc/img_st08_tit@2x.png" alt="" />
                            <span className="blind">수업 일시</span>
                    </h3>
                </div>
                <div className="section-content">
                    <div className="class-item">
                        <p className="st08-txt01">
                            <img src="/images/pc/img_st08_txt01@2x.png" alt="주 3회반 수업시간 35분/회" />
                        </p>
                        <table className="table type-secondary is-fixed">
                            <caption>주 3회반 수업일시 및 시간</caption>
                            <colgroup>
                                <col className="col-auto" />
                            </colgroup>
                            <thead>
                            <tr>
                                <th scope="col">주 3회(월,수,금)</th>
                                <th scope="col">주 2회(화,목)</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td colSpan="2" data-label="월, 수, 금">
                                    <div className="cell">
                                        오후 2:00~2:35 <br/>
                                        오후 2:45~3:20 <br/>
                                        오후 3:30~4:05 <br/>
                                        오후 4:15~4:50 <br/>
                                        오후 5:00~5:35 <br/>
                                        오후 5:45~6:20 <br/>
                                        오후 6:30~7:05 <br/>
                                        오후 7:15~7:50 <br/>
                                        오후 8:00~8:35 <br/>
                                        오후 8:45~9:20
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="class-item">
                        <picture className="st08-txt02">
                            <source media="(max-width: 1024px)" srcSet="/images/mo/img_st08_txt02@2x.png" />
                                <source media="(min-width: 1025px)" srcSet="/images/pc/img_st08_txt02@2x.png" />
                                    <img src="/images/pc/img_st08_txt02@2x.png" alt="월별 진도표 주3회반 기준" />
                        </picture>
                        <div className="table-wrap">
                            <table className="table type-secondary is-fixed">
                                <caption>월별 진도표 주 3회반 기준</caption>
                                <colgroup>
                                    <col className="col-220"/>
                                        <col className="col-auto"/>
                                            <col className="col-auto"/>
                                </colgroup>
                                <thead>
                                <tr>
                                    <th scope="col">월차</th>
                                    <th scope="col">파닉스</th>
                                    <th scope="col">기초영어회화</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        1개월
                                    </td>
                                    <td>
                                        스텝 1
                                    </td>
                                    <td>
                                        챕터 1-4
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        2개월
                                    </td>
                                    <td>
                                        스텝 1&2
                                    </td>
                                    <td>
                                        챕터 5-12
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        3개월
                                    </td>
                                    <td>
                                        스텝 2
                                    </td>
                                    <td>
                                        챕터 13-20
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        4개월
                                    </td>
                                    <td>
                                        스텝 3
                                    </td>
                                    <td>
                                        자율학습
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        5개월
                                    </td>
                                    <td>
                                        스텝 3&4
                                    </td>
                                    <td>
                                        자율학습
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        6개월
                                    </td>
                                    <td>
                                        스텝 4
                                    </td>
                                    <td>
                                        자율학습
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <p className="st08-txt02_2">
                            <img src="/images/pc/img_st08_txt02_2@2x.png" alt="주2회반 기준" />
                        </p>
                        <div className="table-wrap">
                            <table className="table type-secondary is-fixed">
                                <caption>월별 진도표 주 2회반 기준</caption>
                                <colgroup>
                                    <col className="col-220" />
                                        <col className="col-auto" />
                                            <col className="col-auto" />
                                </colgroup>
                                <thead>
                                <tr>
                                    <th scope="col">월차</th>
                                    <th scope="col">파닉스</th>
                                    <th scope="col">기초영어회화</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        1개월
                                    </td>
                                    <td>
                                        스텝 1
                                    </td>
                                    <td>
                                        챕터 1-2
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        2개월
                                    </td>
                                    <td>
                                        스텝 1
                                    </td>
                                    <td>
                                        챕터 7-8
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        3개월
                                    </td>
                                    <td>
                                        스텝 2
                                    </td>
                                    <td>
                                        챕터 9-10
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        4개월
                                    </td>
                                    <td>
                                        스텝 2
                                    </td>
                                    <td>
                                        챕터 11-12
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        5개월
                                    </td>
                                    <td>
                                        스텝 3
                                    </td>
                                    <td>
                                        챕터 13-16
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        6개월
                                    </td>
                                    <td>
                                        스텝 3
                                    </td>
                                    <td>
                                        챕터 17-20
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        7개월
                                    </td>
                                    <td>
                                        스텝 3
                                    </td>
                                    <td>
                                        챕터 21-22
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        8개월
                                    </td>
                                    <td>
                                        스텝 4
                                    </td>
                                    <td>
                                        자율학습
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        9개월
                                    </td>
                                    <td>
                                        스텝 4
                                    </td>
                                    <td>
                                        자율학습
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="class-item">
                        <picture className="st08-txt03">
                            <source media="(max-width: 1024px)" srcSet="/images/mo/img_st08_txt03@2x.png" />
                                <source media="(min-width: 1025px)" srcSet="/images/pc/img_st08_txt03@2x.png" />
                                    <img src="/images/pc/img_st08_txt03@2x.png"
                                         alt="수업선택 방법 - 파닉스 과정에서 스텝을 선택한 후, 해당 월차의 수업 수강" />
                        </picture>
                        <div className="st08-txt03_2 txt-info">
                            <p>
                                권장 사항: 알파벳을 학습한 아이도 스텝 1부터 학습할 것을 권장합니다. <br />
                                수업 도구가 원어민 동영상이어서 듣기 실력 향상에 도움이 되고, 경험상 파닉스 초기 과정의 아이들이 글자 인지 능력이 충분하지 않기 때문입니다.
                            </p>
                        </div>
                    </div>
                    <div className="class-item">
                        <picture className="st08-txt04">
                            <source media="(max-width: 1024px)" srcSet="/images/mo/img_st08_txt04@2x.png" />
                                <source media="(min-width: 1025px)" srcSet="/images/pc/img_st08_txt04@2x.png" />
                                    <img src="/images/pc/img_st08_txt04@2x.png" alt="스텝 1, 2, 3, 4 보기" />
                        </picture>
                        <picture className="st08-cont04_01">
                            <source media="(max-width: 1024px)" srcSet="/images/mo/img_st08_cont04_01@2x.png" />
                                <source media="(min-width: 1025px)" srcSet="/images/pc/img_st08_cont04_01@2x.png" />
                                    <img src="/images/pc/img_st08_cont04_01@2x.png" alt="스텝별 동영상은 유튜브 채널에서도 참고할 수 있습니다." />
                        </picture>
                        <div className="btn-box">
                            <a href="https://www.youtube.com/channel/UC1HVo7ocxToHwWOwull5FHA" target="_blank"
                               className="btn btn-difference">
                                <img src="/images/pc/btn_difference@2x.png" alt="4스텝 파닉스 유튜브 채널 가기" />
                            </a>
                        </div>
                        <div className="st08-txt04_01">
                            유튜브 구독자 수: {youtubeFallow.fallow}{youtubeFallow.comment}
                        </div>
                    </div>

                    <div className="class-item">
                        <picture className="st08-txt05">
                            <source media="(max-width: 1024px)" srcSet="/images/mo/img_st08_txt05@2x.png" />
                                <source media="(min-width: 1025px)" srcSet="/images/pc/img_st08_txt05@2x.png" />
                                    <img src="/images/pc/img_st08_txt05@2x.png" alt="수강료" />
                        </picture>
                        <picture className="st08-cont01">
                            <source media="(max-width: 1024px)" srcSet="/images/mo/img_st08_cont01@2x.png" />
                                <source media="(min-width: 1025px)" srcSet="/images/pc/img_st08_cont01@2x.png" />
                                    <img src="/images/pc/img_st08_cont01@2x.png"
                                         alt="총 6개월 과정 1개월(4주) 65,000원, 3개월(12주) 195,000원" />
                        </picture>
                        <div className="txt-info type-red">
                            <p>
                                * 수업 과정 중간에 합류한 아이는 1개월 기준 남은 수업일 만큼 납입하며, 수업료 납입에 대해 전화 또는 카톡으로 문의주세요.
                            </p>
                        </div>
                        <picture className="st08-cont02">
                            <source media="(max-width: 1024px)" srcSet="/images/mo/img_st08_cont02@2x.png" />
                                <source media="(min-width: 1025px)" srcSet="/images/pc/img_st08_cont02@2x.png" />
                                    <img src="/images/pc/img_st08_cont02@2x.png"
                                         alt="전화: 070-8772-0707, 계좌번호: KB국민은행 300101-04-065166, 예금주: ㈜루시맥스" />
                        </picture>
                        <p className="st08-cont03">
                            <img src="/images/mo/img_st08_cont03@2x.png" alt="" />
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default St08;