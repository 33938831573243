
const St12 = () => {
    return (
        <section id="st12" className="section section-kko">
            <div className="section-inner">
                <div className="section-content">
                    <div className="section-text">
                        <h3 className="st-tit st12-tit">
                            <img src="/images/pc/img_st12_tit@2x.png" alt="" />
                                <span className="blind">고객상담 | 루시맥스 카카오채널</span>
                        </h3>
                        <div className="st12-info">
                            <p><em>문의시간</em> 평일 09:30 ~ 19:00 (토,일,공휴일 휴무)</p>
                            <p className="tel"><em>문의전화</em> 070-8772-0707, 010-4757-0721</p>
                        </div>
                    </div>
                    <div className="kko-name">
                        <a href="https://pf.kakao.com/_VyKsb" target="_blank">
                            @4스텝 파닉스
                        </a>
                    </div>

                    <div className="m-st12-info">
                        <p><em>문의시간</em> 평일 09:30 ~ 19:00 (토,일,공휴일 휴무)</p>
                        <p className="tel"><em>문의전화</em> 070-8772-0707, 010-4757-0721</p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default St12;