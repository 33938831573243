import {useEffect} from "react";
import {useRef} from "react";


const St02 = () => {
    return (
        <section id="st02" className="section section-eduClass">
            <div className="section-inner">
                <div className="section-title">
                    <h3 className="st-tit st02-tit">
                        <img src="/images/pc/img_st02_tit@2x.png" alt=""/>
                            <span className="blind" >수업 모습</span>
                    </h3>
                </div>
                <div className="section-content">
                    <div className="card">
                        <picture className="st02-txt01" style={{marginBottom: 16.5}}>
                            <source media="(max-width: 1024px)" srcSet="/images/mo/m_class1@2x.png" />
                                <source media="(min-width: 1025px)" srcSet="/images/pc/class1@2x.png" />
                            <img src="/images/pc/class1@2x.png"
                                 alt="수업 내용 Preview 및 Review 테스트를 위한 PPT 자료와 어린이 영어 학원에서 10년 동안 가르친 캐나다인 바바라 선생님의 파닉스 동영상 강의를 한국인 온라인 강사가 적극적 참여 유도 및 재생/일시정지 하면서 수업 진행 - 1대6 실제 수업 장면(수업 3일차의 예) " />
                        </picture>
                        <picture className="st02-txt01" >
                            <source media="(max-width: 1024px)" srcSet="/images/mo/sub_m_class1@2x.png" />
                            <source media="(min-width: 1025px)" srcSet="/images/pc/sub_class1@2x.png" />
                            <img src="/images/pc/sub_class1@2x.png"
                                 alt="sub" />
                        </picture>
                        <div className="st02-cont st02-cont01">
                            <div className="video-box">
                                <iframe src="https://tv.naver.com/embed/38899020" scrolling="no"
                                        title="파닉스 영상"></iframe>
                            </div>
                        </div>
                    </div>
                    {/*<div className="card">*/}
                    {/*    <picture className="st02-txt02">*/}
                    {/*        <source media="(max-width: 1024px)" srcSet="/images/mo/img_st02_txt02@2x.png" />*/}
                    {/*            <source media="(min-width: 1025px)" srcSet="/images/pc/img_st02_txt02@2x.png" />*/}
                    {/*                <img src="/images/pc/img_st02_txt02@2x.png"*/}
                    {/*                     alt="동영상에는 필요한 부분마다 커다란 한국어 자막이 있어서 영어를 처음 학습하는 아이도 원어민 수업을 쉽게 이해" />*/}
                    {/*    </picture>*/}
                    {/*</div>*/}
                    <div className="card">
                        <picture className="st02-txt03">
                            <source media="(max-width: 1024px)" srcSet="/images/mo/img_st02_txt03@2x.png" />
                                <source media="(min-width: 1025px)" srcSet="/images/pc/img_st02_txt03@2x.png" />
                                    <img src="/images/pc/img_st02_txt03@2x.png"
                                         alt="많은 이미지도 활용해서, 온라인 강사와 어린이 학습자들 간에 적극적 상호작용 - 1:5 화상 수업 2개월 차 모습" />
                        </picture>

                        <div className="st02-cont st02-cont03">
                            <div className="video-box">
                                <iframe src="https://tv.naver.com/embed/33792652" scrolling="no" title="파닉스 영상"></iframe>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <picture className="st02-txt04">
                            <source media="(max-width: 1024px)" srcSet="/images/mo/img_st02_txt04@2x.png" />
                                <source media="(min-width: 1025px)" srcSet="/images/pc/img_st02_txt04@2x.png" />
                                    <img src="/images/pc/img_st02_txt04@2x.png"
                                         alt="기초 영어회화 애니메이션 수업도 파닉스 수업과 같은 방식으로 수업" />
                        </picture>

                        <div className="st02-cont st02-cont04">
                            <div className="video-box">
                                <iframe src="https://tv.naver.com/embed/28690919" scrolling="no"
                                        title="파닉스 영상"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default St02;