import St01 from "./St01";
import St02 from "./St02";
import St03 from "./St03";
import St04 from "./St04";
import St05 from "./St05";
import St06 from "./St06";
import St07 from "./St07";
import St08 from "./St08";
import St09 from "./St09";
import St10 from "./St10";
import St11 from "./St11";
import St12 from "./St12";
import St011 from "./St011";
import {useEffect, useState} from "react";
import Axios from "../../axios/Axios";

const Sections = () => {

        const [youtubeFallow , setYoutubeFallow] = useState({});
        useEffect(() => {
            Axios.get("/user/youtube_fallow/select" , null , false).then( result => {
                setYoutubeFallow(result.data.data.count[0]);
            })
        }, [])

        return (
            <section id="wrap" className="wrap main-wrap">
                    <h2 className="blind">본문</h2>
                    <St01 youtubeFallow={youtubeFallow}/>
                    <St011/>
                    <St02/>
                    <St03/>
                    <St04/>
                    <St05/>
                    <St06/>
                    <St07/>
                    <St08 youtubeFallow={youtubeFallow} />
                    <St09/>
                    <St10/>
                    <St11/>
                    <St12/>
            </section>
        );
}

export default Sections;