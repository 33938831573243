import {useSelector} from "react-redux";


const useMenuModal = () => {
    const menuModal = useSelector(state => state.menuModalReducer);

    if(!menuModal) throw new Error("프로바이더가 없습니다.");

    return menuModal;
}

export default useMenuModal;