import {useEffect} from "react";


const Fail = () => {
    let code = new URL(window.location.href).searchParams.get("code");
    let message = new URL(window.location.href).searchParams.get("message");
    let orderId = new URL(window.location.href).searchParams.get("orderId");

    useEffect(() => {
        const data = {
            code: code,
            message: message,
            orderId: orderId
        }


        const payReturnFalse = (data) => {

        };

    }, [])

    return (
        <>
        </>
    )
}

export default Fail;