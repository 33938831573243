
const St11 = () => {
    return (
        <section id="st11" className="section section-faq">
            <div className="section-inner">
                <div className="section-title">
                    <picture className="st-tit st11-tit">
                        <source media="(max-width: 1024px)" srcSet="/images/mo/img_st11_tit@2x.png" />
                            <source media="(min-width: 1025px)" srcSet="/images/pc/img_st11_tit@2x.png" />
                                <img src="/images/pc/img_st11_tit@2x.png" alt="등록 전, Q&A를 반드시 읽어 주세요" />
                    </picture>
                </div>
                <div className="section-content">
                    <div className="accodion-wrap">
                        <div className="accodion">
                            <input type="checkbox" id="rd1" name="rd" className="faqItem" defaultChecked={true} />
                                <label className="accodion-label" htmlFor="rd1">
                                    <span className="txt">1:1 수업과 비교 시 동시에 여러 명이 하는 학습 효과는 어떤가요?</span>
                                    <span className="arrow"></span>
                                </label>
                                <div className="accodion-content">
                                    <p className="answer">
                                    <span>
                                        루시맥스 줌수업은 동영상을 주로 사용하고, 참여 어린이들이 레벨은 기초 과정이므로 <br className="m-hidden" />학습자 한 명보다는 여러 명과 함께 하는 수업의 학습 효과가 높습니다.<br/> 여러 명의 아이들아 함께 하면서 집중력이 올라가기 때문입니다.
                                    </span>
                                    </p>
                                </div>
                        </div>
                        <div className="accodion">
                            <input type="checkbox" id="rd2" name="rd" className="faqItem" />
                                <label className="accodion-label" htmlFor="rd2">
                                    <span className="txt">수업에 집중을 못 하고 다른 아이들의 수업에 방해되는 아이가 있지 않을까요?</span>
                                    <span className="arrow"></span>
                                </label>
                                <div className="accodion-content">
                                    <p className="answer">
                                    <span>
                                        있을 수 있습니다. 다른 아이의 수업에 지장을 주는 아이의 경우, 우선 수업 참여를 잘할 수 있도록 학부모와 함께 노력합니다.<br />
                                        하지만, 수업에 지장이 지속되는 경우, 해당 아이 또한 수업 참여가 학습에 도움되지 않기 때문에, 더 이상의 수업 참여가 불가함을 알립니다.
                                    </span>
                                    </p>
                                </div>
                        </div>
                        <div className="accodion">
                            <input type="checkbox" id="rd3" name="rd" className="faqItem" />
                                <label className="accodion-label" htmlFor="rd3">
                                    <span className="txt">결석하는 아이에 대한 보충 수업이 있나요?</span>
                                    <span className="arrow"></span>
                                </label>
                                <div className="accodion-content">
                                    <p className="answer">
                                    <span>
                                        보충 수업을 별도 진행하지 않습니다.<br />
                                        수업 내용을 동영상, 앱, 교재로 언제든지 자기주도 학습할 수 있기 때문에, 해당일 결석한 어린이는 결석일 수업 내용에 대해 자기 주도 학습을 권하고 있습니다.
                                    </span>
                                    </p>
                                </div>
                        </div>
                        <div className="accodion">
                            <input type="checkbox" id="rd4" name="rd" className="faqItem" />
                                <label className="accodion-label" htmlFor="rd4">
                                    <span className="txt">여러 명 수업이므로 음소거를 하고 수업을 진행하나요?</span>
                                    <span className="arrow"></span>
                                </label>
                                <div className="accodion-content">
                                    <p className="answer">
                                    <span>
                                        음소거를 해제하고, 즉 아이의 소리가 들리는 상태로 수업이 진행됩니다.
                                        우리 아이는 35분 수업 시간 동안 50번 이상 대답을 하는 데, 음소거 해제가 더욱 도움되기 때문입니다. 또한, 우리 아이의 대답 및 다른 아이들의 대답이 모든 아이들의 수업참여도를 높이므로 음소거 해제를 원칙으로 하고 있습니다.
                                        단, 집 안에서 발생되는 소리로 인해 다른 아이들의 수업에 방해가 되는 경우, 음소거(강사 또는 참가자가 조절)를 하고 있습니다.
                                    </span>
                                    </p>
                                </div>
                        </div>
                        <div className="accodion">
                            <input type="checkbox" id="rd5" name="rd" className="faqItem" />
                                <label className="accodion-label" htmlFor="rd5">
                                    <span className="txt">등록시, 레벨 테스트를 하나요?</span>
                                    <span className="arrow"></span>
                                </label>
                                <div className="accodion-content">
                                    <p className="answer">
                                    <span>
                                        파닉스는 가장 기초 과정이므로 레벨 테스트가 없습니다. 아이 수준을 학부모가 어느 정도 알고 있으므로 해당 과정에서 수업을 실시합니다. 단, 수업 도중 아이의 과정(예; 학부모는 이중모음 과정이라고 하지만, 저희가 보기에 단모음 과정이 더욱 적합한 경우)이 변경될 수 있습니다.
                                    </span>
                                    </p>
                                </div>
                        </div>
                        <div className="accodion">
                            <input type="checkbox" id="rd6" name="rd" className="faqItem" />
                                <label className="accodion-label" htmlFor="rd6">
                                    <span className="txt">수업 연령은 어떻게 되나요?</span>
                                    <span className="arrow"></span>
                                </label>
                                <div className="accodion-content">
                                    <p className="answer">
                                    <span>
                                        만5세~만9세 입니다. 학습 구성은 두 살 차이까지를 (예, 만 5~7세, 만 6~8세, 만 7~9세) 최대한의 범위로 두고 있지만, 수업 인원이 많아질수록 최대 한 살 차이로 학급을 구성하려고 합니다.
                                    </span>
                                    </p>
                                </div>
                        </div>
                        <div className="accodion">
                            <input type="checkbox" id="rd7" name="rd" className="faqItem" />
                                <label className="accodion-label" htmlFor="rd7">
                                    <span className="txt">파닉스 과정 이후에도 루시맥스 화상 수업을 할 수 있나요?</span>
                                    <span className="arrow"></span>
                                </label>
                                <div className="accodion-content">
                                    <p className="answer">
                                    <span>
                                        저희는 어린이 영어 기초 과정(파닉스, 기초 영어 회화)만 화상 수업을 진행합니다. 저희 유튜브에 파닉스 이후 과정의 레벨별 리딩, 영어 회화, 듣기 평가 등이 있지만, 화상 수업으로는 기초 과정만 진행합니다.
                                    </span>
                                    </p>
                                </div>
                        </div>
                        <div className="accodion">
                            <input type="checkbox" id="rd8" name="rd" className="faqItem" />
                                <label className="accodion-label" htmlFor="rd8">
                                    <span className="txt">정원 5명이 동시에 수업을 시작하나요?</span>
                                    <span className="arrow"></span>
                                </label>
                                <div className="accodion-content">
                                    <p className="answer">
                                    <span>
                                        정원 5명이 채워지지 않은 경우 5명 미만으로 수업을 시작합니다. 도중에 신규 학습자가 등록할 경우 최대 5명으로 수업이 구성됩니다.
                                    </span>
                                    </p>
                                </div>
                        </div>
                        <div className="accodion">
                            <input type="checkbox" id="rd9" name="rd" className="faqItem" />
                                <label className="accodion-label" htmlFor="rd9">
                                    <span className="txt">학부모 상담을 해주나요?</span>
                                    <span className="arrow"></span>
                                </label>
                                <div className="accodion-content">
                                    <p className="answer">
                                    <span>
                                        아이의 학습 모습을 직접 보시기 때문에 학부모 상담을 하지 않습니다.
                                        아이가 수업을 충분히 이해하지 못하는 경우, 별도 학습 방법을 알려드립니다.
                                    </span>
                                    </p>
                                </div>
                        </div>
                        <div className="accodion">
                            <input type="checkbox" id="rd10" name="rd" className="faqItem" />
                                <label className="accodion-label" htmlFor="rd10">
                                    <span className="txt">월초가 지나서 등록하는 학습자의 수강료 금액은 어떻게 되나요?</span>
                                    <span className="arrow"></span>
                                </label>
                                <div className="accodion-content">
                                    <p className="answer">
                                    <span>
                                        해당 월 남은 수업일수와 다음 달 수업료를 한꺼번에 지불합니다.
                                        예를 들어 해당 월 3주 차에 주 3회 수업을 등록하면, (33,000원 + 66,000원 = 99,000원)을 지불합니다. 일반 학원에서 수업 기간 중 신규 학생이 등록할 때 지불하는 방법과 동일합니다.
                                    </span>
                                    </p>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default St11;