import {useRef, useState} from "react";
import Axios from "../axios/Axios";
import {useDispatch} from "react-redux";
import {activeUserToken} from "../store/reducers/userGuardReducer";
import {regEmail, regName, regPassword, regPhoneNumber} from "../util/reg";
import {useNavigate} from "react-router-dom";
import {showAlert} from "../store/reducers/alertDialogSlideRedux";

const FindEmail = () => {

    const [phoneNumberError , setPhoneNumberError] = useState(false);
    const [nameError , setNameError] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [phoneNumber , setPhoneNumber] = useState("");
    const [name , setName] = useState("");

    const handlePhoneNumber = (event) => {
        if(regPhoneNumber.test(event.target.value)) {
            setPhoneNumberError(false);
        }
        setPhoneNumber(event.target.value);
    }

    const handleBlurPhoneNumber = (event) => {
        if(!regPhoneNumber.test(event.target.value)) {
            setPhoneNumberError(true);
        } else {
            setPhoneNumber(event.target.value);
        }
    }


    const handleName = (event) => {
        if(regName.test(event.target.value)) {
            setNameError(false);
        }
        setName(event.target.value);
    }

    const handleBlurName = (event) => {
        if(!regName.test(event.target.value)) {
            setNameError(true);
        } else {
            setName(event.target.value);
        }
    }


    const handleSubmit = () => {
        const param = {
            phone_number: phoneNumber,
            name: name
        }
        Axios.get("/user/user/findEmail" , param , false).then( result => {
            if (result.email != null && result.email !== undefined){
                console.log(result.email)
                navigate("/user/successFindEmail/" + result.email.email , { state: result.email.email });
            } else {
                dispatch(
                    showAlert({
                        items: 1,
                        title: '아이디 찾기 실패',
                        message: '올바른 정보를 입력해 주세요.',
                        text1: '닫기'
                    }),
                );
            }
        })
    }


    const enterFunction = (event) => {
        if(event.keyCode === 13){
            handleSubmit()
        }
    }

    return (
        <section id="wrap" className="wrap subpage-wrap">
            <h2 className="blind">본문</h2>
            <div className="container">
                <div className="content">
                    <article className="login-wrap">
                        <h3 className="content-title">아이디 찾기</h3>
                        <div className="content-box">
                            <div className="form-area">
                                <form className="form-vertical" >
                                    <div className= {nameError ? "form-group has-error has-icon-right" : "form-group has-icon-right"} >
                                        <label className="form-label" htmlFor="input-userName">학습자 이름</label>
                                        <input className="form-input" id="input-userName" type="text"
                                               name="userName"
                                               placeholder="이름을 입력 해주세요."
                                               onChange={(e) => {
                                                   handleName(e);
                                               }}
                                               onKeyDown={enterFunction}
                                               onBlur={ (e)=> {
                                                   handleBlurName(e);
                                               }}
                                        />
                                    </div>
                                    <div className= {phoneNumberError ? "form-group has-error has-icon-right" : "form-group has-icon-right"} >
                                        <label className="form-label" htmlFor="input-userPW">휴대폰 번호</label>
                                        <input className="form-input" id="input-userPW" type="tel"
                                               name="password"
                                               placeholder="휴대폰 번호를 입력 해주세요."
                                               onChange={(e) => {
                                                   handlePhoneNumber(e);
                                               }}
                                               onKeyDown={enterFunction}
                                               onBlur={ (e)=> {
                                                   handleBlurPhoneNumber(e);
                                               }}
                                        />
                                    </div>
                                    <div className="form-input-desc">
                                        <p>회원가입 시 작성한 휴대폰 번호와 학습자 이름을 입력해주세요.</p>
                                    </div>

                                    <div className="btn-submit-group">
                                        <button type="button" className="btn-form-submit btn-block" onClick={ () => {
                                            handleSubmit()
                                        }}>아이디 찾기</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div style={{marginTop: 3}}>
                            <button type="button" className="btn" style={{float:"right", padding:5}} onClick={ () => {
                                navigate("/login");
                            }}><span style={{fontSize:14, color: "gray" , textDecoration:"underline"}}>로그인</span></button>

                            <button type="button" className="btn" style={{float:"right", padding:5}} onClick={ () => {
                                navigate("/user/findPassword");
                            }}><span style={{fontSize:14, color: "gray" , textDecoration:"underline"}}>비밀번호 찾기</span></button>
                        </div>
                    </article>
                </div>
            </div>
        </section>
    );
}

export default FindEmail;