import {useState} from "react";

const St03 = () => {

    const [checked , setChecked] = useState([true , false]);

    const handleCheck = (e) => {
        e.target.id === 'tab-1' ? (e.target.value === 'on' && setChecked([true , false])) : (e.target.value === 'on' && setChecked([false , true]))
    }

    return (
        <section id="st03" className="section section-homeWork">
            <div className="section-inner">
                <div className="section-title">
                    <h3 className="st-tit st03-tit">
                        <img src="/images/pc/img_st03_tit@2x.png" alt="" />
                            <span className="blind">숙제 - 줌 수업을 통해 배운 수업 내용을 앱 퀴즈와 교재로 복습</span>
                    </h3>
                </div>
                <div className="section-content">
                    <div className="tabs">
                            <input type="radio" id="tab-1" name="tab-effect-1"  defaultChecked={checked[0]} onClick={ (e) => {handleCheck(e)}}/>
                            <label htmlFor="tab-1">
                                <span className="st03-tab-tit01"><img src="/images/pc/img_st03_tab_tit01@2x.png"
                                                                      alt="루시맥스 앱" /></span>
                            </label>
                            <input type="radio" id="tab-2" name="tab-effect-1" defaultChecked={checked[1]} onClick={(e) => {handleCheck(e)}}/>
                                <label htmlFor="tab-2">
                                    <span className="st03-tab-tit02"><img src="/images/pc/img_st03_tab_tit02@2x.png"
                                                                          alt="교재" /></span>
                                </label>
                                <div className="tab-content">
                                    <div id="tab-item-1" className="tab-container">
                                        <div className="st03-cont st03-cont01">
                                            <img src="/images/pc/img_st03_cont01@2x.png" alt="" />
                                        </div>
                                    </div>
                                    <div id="tab-item-2" className="tab-container">
                                        <picture className="st03-cont st03-cont02">
                                            <source media="(max-width: 1024px)"
                                                    srcSet="/images/mo/img_st03_cont02@2x.png" />
                                                <source media="(min-width: 1025px)"
                                                        srcSet="/images/pc/img_st03_cont02@2x.png" />
                                                    <img src="/images/pc/img_st03_cont02@2x.png" alt="교재 샘플 이미지" />
                                        </picture>
                                        <picture className="st03-cont st03-cont02_2">
                                            <source media="(max-width: 1024px)"
                                                    srcSet="/images/mo/img_st03_cont02_2@2x.png" />
                                                <source media="(min-width: 1025px)"
                                                        srcSet="/images/pc/img_st03_cont02_2@2x.png" />
                                                    <img src="/images/pc/img_st03_cont02_2@2x.png"
                                                         alt="화상 수업에 교재는 필수 도구이므로 네이버 쇼핑에서 교재를 구매해 주세요. (네이버 쇼핑에는 교재에 관한 자세한 내용이 있습니다.)" />
                                        </picture>
                                    </div>
                                </div>
                    </div>
                    <div className="btn-box">
                        <div className="btn-item">
                            <p className="btn-item-tit btn-item-tit01">
                                <img src="/images/pc/img_st03_btn_tit01@2x.png" alt="무료 앱 다운로드" />
                            </p>
                            <a href="https://play.google.com/store/apps/details?id=com.lucymax.app" target="_blank"
                               className="btn btn-android">
                                <img src="/images/pc/btn_android@2x.png" alt="구글에서 다운로드" />
                            </a>
                            <a href="https://apps.apple.com/us/app/id1523398321" target="_blank"
                               className="btn btn-ios">
                                <img src="/images/pc/btn_ios@2x.png" alt="애플에서 다운로드" />
                            </a>
                        </div>
                        <div className="btn-item">
                            <p className="btn-item-tit btn-item-tit02">
                                <img src="/images/pc/img_st03_btn_tit02@2x.png" alt="교재 구입" />
                            </p>
                            <a href="https://smartstore.naver.com/lucymax/products/5184590784" target="_blank"
                               className="btn btn-shop">
                                <img src="/images/pc/btn_shop@2x.png" alt="네이버 쇼핑에서 교재 구매" />
                            </a>
                        </div>
                    </div>
                    <div className="txt-info type-red">
                        <p>
                            * 유튜브 동영상, 앱, 교재만으로 (줌수업을 하지 않으면서) 엄마표 영어로 학습이 가능합니다.
                        </p>
                        <p>
                            * [네이버 쇼핑에서 교재 구매] 버튼을 누르면 네이버 쇼핑에서 많은 리뷰와 함께 엄마표 영어로 학습하는 자세한 정보를 확인할 수 있습니다.
                        </p>
                        <p>
                            * 줌수업과 유튜브 자기주도학습 차이는 아래를 더 내려가면 표로 정리하였습니다.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default St03;