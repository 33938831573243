import {useRef, useState} from "react";
import {regEmail, regNumber, regPassword, regPhoneNumber} from "../util/reg";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import Axios from "../axios/Axios";
import {showAlert} from "../store/reducers/alertDialogSlideRedux";

const Registry = () => {
    // Validation
    const [showCheckPassword , setShowCheckPassword] = useState(false);
    const [passwordError , setPasswordError] = useState(false);
    const [checkPhoneNumberError ,setCheckPhoneNumberError] = useState(false);
    const [checkPasswordError , setCheckPasswordError] = useState(false);
    const [checkIdError , setCheckIdError] = useState(0) // 0 정상 , 1 이메일 검증 에러 , 2 중복에러

    // ref
    const ageRef = useRef();
    const phoneNumberRef = useRef();
    const nameRef = useRef();
    const codeRef = useRef();

    const [showPassword ,setShowPassword] = useState(false);
    const [password , setPassword] = useState("");
    const [checkPassword , setCheckPassword] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [id , setId] = useState("");


    const handlePassword = (event) => {
        setPassword(event.value)
        if(regPassword.test(event.value)) {
            setPasswordError(false);
        }
    }

    const handleBlurPhoneNumberCheck = (event) => {
        if(!regPhoneNumber.test(event.value)){
            setCheckPhoneNumberError(true);
        } else {
            setCheckPhoneNumberError(false);
        }
    }

    const handleBlurId = (event) => {
        if(!regEmail.test(event.value)){
            setCheckIdError(1);
        } else {
            const params = {
                email: event.value
            }
            setCheckIdError(0);
            Axios.get("/user/user/findByUserId" , params , false).then(result => {
                    const count = result.emailCnt;
                    if( count !== 0) {
                        setCheckIdError(2);
                    } else {
                        setCheckIdError(0);
                        setId(event.value);
                    }
            })

        }
    }

    const handleBlurPassword = (event) => {
        if(!regPassword.test(event.target.value)) {
            setPasswordError(true);
        }
    }

    const handleBlurPasswordCheck = (event) => {
        if(password !== event.target.value){
            setCheckPasswordError(true)
        } else {
            setCheckPassword(event.target.value);
            setCheckPasswordError(false);
        }
    }

    const dataValidation = (current) => {
        const names = current.id === "input-userName" ? "학습자 이름" : (current.id === "input-userPhone") ?  "휴대폰 번호" : "학습자 나이";

        if(current.value === "" || current.value == null){
            throw new Error(names + ' 에 알맞은 값을 입력해주세요.');
        } else {
            return current.value;
        }
    }

    const checkErrors = () => {
        if(checkPassword !== password) {
            setCheckPasswordError(true);
            throw new Error("비밀번호와 확인 비밀번호를 확인해주세요.");
        }
        if(id === "" || password === "" || password == null || id == null) throw new Error("회원 가입 정보를 다시 한번 확인해주세요.");
        if(checkPhoneNumberError || checkIdError || checkPasswordError || passwordError ) throw new Error("회원 가입 정보를 다시 한번 확인해주세요.");
    }

    const joinSubmit = () => {
        try {
            checkErrors();
            const name = dataValidation(nameRef.current);
            const age = dataValidation(ageRef.current);
            const phoneNumber = dataValidation(phoneNumberRef.current);
            const code = codeRef.current.value;
            const data = new FormData();
            data.append("email" , id);
            data.append("password" , password);
            data.append("name" , name);
            data.append( "age" , age );
            data.append("phone_number" , phoneNumber);
            data.append("code", code)
            Axios.post("/user/user/join" , data , true).then(result => {
                dispatch(
                    showAlert({
                        items: 1,
                        title: '성공',
                        message: '회원가입이 완료되었습니다.',
                        sucCallback: () => {navigate('/login')},
                        text1: '닫기'
                    }),
                );
            })

        } catch (e) {
            dispatch(
                showAlert({
                    items: 1,
                    title: '요청 실패',
                    message: e.message,
                    text1: '닫기'
                }),
            );
        }
    }

    return (
        <section id="wrap" className="wrap subpage-wrap">
            <h2 className="blind">본문</h2>
            <div className="container">
                <div className="content">
                    <article className="join-wrap">
                        <h3 className="content-title">회원가입</h3>
                        <div className="content-box">
                            <div className="form-area">
                                <form className="form-vertical" >
                                    <div className={checkIdError !== 0 ? "form-group has-error" : "form-group"}>
                                        <label className="form-label" htmlFor="input-userMail">이메일</label>
                                        <input className="form-input" id="input-userMail" type="email"
                                               placeholder="이메일 입력"
                                               onBlur={(e) => {
                                                         handleBlurId(e.target);
                                               }}
                                        />
                                        {checkIdError === 1 ? <p className="form-error-check">이메일 형식이 아닙니다. 이메일을 입력해주세요.</p> :
                                            (checkIdError === 2) ? <p className="form-error-check">이미 존재하는 이메일 입니다. 다른 이메일을 입력해주세요.</p> : null}
                                    </div>
                                    <div className= {(checkPasswordError || passwordError) ?  "form-group has-icon-right has-error": "form-group has-icon-right"}>
                                        <label className="form-label" htmlFor="input-userPW">비밀번호</label>
                                        <input className="form-input" id="input-userPW" type={showPassword ? "text" : "password"}
                                               placeholder="비밀번호 입력"
                                               onBlur={ (e)=> {
                                                   handleBlurPassword(e);
                                               }}
                                               onChange={(e) => {
                                                   handlePassword(e.target);
                                               }}
                                            />
                                            <i className="icon form-icon btn-icon eye-show" attr-icon="eye-hide"
                                               role="button" onClick={()=>{setShowPassword(!showPassword)}}></i>
                                            {passwordError ? <p className="form-error-check">
                                                올바른 비밀번호를 입력해주세요.<br/>
                                                영어, 숫자, 특수문자를 포함한 6자리 이상의 비밀번호를 입력해주세요.
                                            </p> : null}
                                    </div>
                                    <div className={checkPasswordError ?  "form-group has-icon-right has-error": "form-group has-icon-right"}>
                                        <label className="form-label" htmlFor="input-userPWck">비밀번호 확인</label>
                                        <input className="form-input" id="input-userPWck" type={showCheckPassword ? "text" :"password"}
                                               placeholder="비밀번호 확인"
                                               onBlur={ (e)=> {
                                                   handleBlurPasswordCheck(e);
                                               }}
                                         />
                                            <i className="icon form-icon btn-icon eye-show" attr-icon="eye-hide"
                                               role="button" onClick={() =>{setShowCheckPassword(!showCheckPassword)}}></i>
                                        {checkPasswordError ? <p className="form-error-check">입력하신 비밀번호가 일치하지 않습니다.</p> : null}
                                    </div>
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="input-userName">학습자 이름</label>
                                        <input className="form-input" id="input-userName" type="text" ref={nameRef}/>
                                    </div>
                                    <div className={checkPhoneNumberError ? "form-group has-error" : "form-group"}>
                                        <label className="form-label" htmlFor="input-userPhone">휴대폰 번호</label>
                                        <input className="form-input" id="input-userPhone" type="tel"
                                                placeholder="숫자만 입력해주세요." ref={phoneNumberRef}
                                               onBlur={ (e)=> {
                                                   handleBlurPhoneNumberCheck(e.target);
                                               }}
                                        />
                                        {checkPhoneNumberError ? <p className="form-error-check">휴대폰번호를 숫자만 입력해주세요.</p> : null}
                                    </div>
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="input-userAge">학습자 나이 (만 나이)</label>
                                        <input className="form-input" id="input-userAge" type="number"  min="1" ref={ageRef}/>
                                    </div>
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="input-code">프로모션 코드 (선택) </label>
                                        <input className="form-input" id="input-code" type="text"  ref={codeRef}/>
                                    </div>
                                    <div className="btn-submit-group">
                                        <button type="button" className="btn-form-submit btn-block" onClick={ () => {joinSubmit()}} >회원가입</button>
                                    </div>
                                    <div className="form-input-desc">
                                        <p>1. 학습 대상 연령: 만 5세 ~ 만 9세</p>
                                        <p>2. 회원 정보는 1년간 유지되며, 1년 이후에는 자동 삭제되므로 필요시 재가입 해주셔야 합니다.</p>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </article>
                </div>
            </div>
        </section>
    );
}

export default Registry;